import * as actionTypes from "./actiontypes"
import states from "./states"

const intent_details = (state = states.intent_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_INTENT_STATE:
      return {
        ...state,
        ...action.payload
      }

    case actionTypes.INTENT_ANALYTICS_UPDATE:
      return {
        ...state,
        intent_analytics: {
          ...state.intent_analytics,
          ...action.payload
        }
      }

    default:
      return state
  }
}

export default intent_details
