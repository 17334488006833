import states from "./states"
import { CA_UPDATE_STATE } from "./actiontypes"

const chatlog_details = (state = states.chatlog_details, action) => {
  switch (action.type) {
    case CA_UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default chatlog_details
