//tm => template_master
const states = {
  template_master_details: {
    template_master: [],
    tm_has_more: false,
    tm_search_key: "",
    tm_loading: false,
    tm_load_error: false,
    tm_selected_lang: "",
    tm_istemplatename_exist: null,
    tm_selected_data: {
      messageDetail: {},
    },
    tm_file: {},
    tm_upload_percent: 0,
    quota: [],
    quota_loader: false,
    tm_file_schedule: "",
  },
}

export default states
