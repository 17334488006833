const states = {
  catalogue_details: {
    //--------------------- CATALOGUE HOME  ----------------------
    products: [],
    search_key: "",
    catalogueSettings: {},
    has_more: false,
    loading: false,
    load_error: false,

    //--------------------- EDIT CATALOGUE => ec  ----------------------

    sync: true,
    product: {},
    add_new_category: false,
    selected_category: {},

    //----------------------- Need validation in below states ----------

    ec_categories: [
      {
        title: "Title 1",
        isActive: true,
        children: [
          {
            title: "Title",
            isActive: false,
            children: [{ title: "Title", isActive: true, children: [] }],
          },
        ],
      },
      {
        title: "Title 2",
        isActive: false,
        children: [],
      },
    ],

    ec_selected_tree_item: "1-0",
    ec_messages: [
      {
        title: "Whatsapp + FR",
        type: "Text + Image",
      },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
    ],
    ec_settings: {
      intent: "",
      alias: [],
      workflowId: "",
      nodeId: "",
      workflowVariable: "",
      userSuggestions: true,
    },
  },
}
export default states
