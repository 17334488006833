import states from "./states"
import {
  UPDATE_GENERATIVE_FAQ_STATE,
  MORE_GENERATIVE_FAQS_LOADED,
  FAQ_CREATED,
  FAQ_DELETED,
  FAQ_UPDATED
} from "./actiontypes"
import { MODULE_CONFIG } from "../../config/setup"

const generativefaq_details = (
  state = states.generativefaq_details,
  action
) => {
  switch (action.type) {
    case UPDATE_GENERATIVE_FAQ_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case MORE_GENERATIVE_FAQS_LOADED:
      return {
        ...state,
        faqs: [...state.faqs, ...action.payload],
        gf_has_more:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.GENERATIVE_FAQ_RECORDS_PER_PAGE_DATA,
      }

    case FAQ_CREATED:
      return {
        ...state,
        faqs: [action.payload, ...state.faqs],
      }

    case FAQ_DELETED: {
      let index = -1
      index = state.faqs.findIndex(faq => faq._id === action.payload)
      if (index !== -1)
        return {
          ...state,
          faqs: [...state.faqs.slice(0, index), ...state.faqs.slice(index + 1)],
        }
      return state
    }

    case FAQ_UPDATED: {
        let index = -1
        index = state.faqs.findIndex(
          faq => action.payload._id === faq._id
        )
        if (index !== -1)
          return {
            ...state,
            faqs: [
              ...state.faqs.slice(0, index),
              {
                ...state.faqs[index],
                ...action.payload,
              },
              ...state.faqs.slice(index + 1),
            ],
          }
        return state
      }
  

    default:
      return state
  }
}

export default generativefaq_details
