const states = {
  intent_details: {
    //--------------- Intent Records ----------------
    intent_list: [], // il => intent_list
    il_current_page: 1,
    il_page_size: 10,
    il_total: 0,
    il_initial_load: true,
    il_loading: false,
    il_load_error: false,
    il_sort_order: null,
    il_sort_field: null,
    il_search_key: "",
    il_search_type: "intent",
    il_status: true,

    //--------------------- Edit intent ----------------------
    initial_intent_data: {},
    active_intent_data: {
      resetWorkflows: [],
      resetParameters: [],
      messageResponse: [], // TODO: GET THE "message_before" key updated with "messageResponse"
    },
    intent_data_loading: false,
    intent_data_load_error: false,

    wf_data_loading: false,
    wf_data_load_error: false,
    wf_data: [],

    //--------------------- Word Cloud ----------------------
    wc_data: [],
    wc_loading: false,
    wc_load_error: false,

    //--------------------- Intent Analytics ------------------

    intent_analytics: {
      intents_stats: [],
      lang_stats: [],
      intents_stats_loading: false,
      intents_stats_load_error: false,
      languages: [],
      histogram: [],
      average: null,
      ia_loading: false,
      ia_load_error: false,
    },
    //===========utterances table==========
    ut_search_key: "",
    ut_sort_order: null,
    ut_sort_field: null,
    ut_filters: null,
    ut_current_page: 1,
    ut_page_size: 5,
    ut_total: 15,
    default_lang: "en",
    utterances: [],
    ut_lang_options: [],
    ut_intent_list: [],
    allowed_lang: [],
    ut_loading: false,
    ut_load_error: false,

    //--------------- Intent Blocking ----------------
    intent_blocked_list: [],
    intent_allowed_channels: {},
    ib_loading: false,
    ib_load_error: false,
    ic_loading: false,
    ic_load_error: false,
    ib_current_page: 1,
    ib_page_size: 10,
    ib_total: 0,
    ib_search_key: ""
  },
}
export default states
