/**
 * cn_tree dummy data 
  const cn_tree = [
    {
      title: "hlo",
      key: "0",
      _id: "1",
      messages: ["hello how are you?", "To kaise h aaplog?"]
    },
    {
      title: "HEADER 1",
      key: "0-0",
      selectable: false,
      children: [
        {
          title: "SUBHEADER 1 ",
          key: "0-0-0",
          selectable: false,
          children: [
            {
              title: "shortcut1",
              key: "0-0-0-0",
              _id: "21",
              messages: ["shortcut1 message 1", "shortcut1 message 2", "shortcut1 message 3"]
            },
            {
              title: "shortcut2",
              key: "0-0-0-1",
              _id: "22",
              messages: ["shortcut2 message 1", "shortcut2 message 2"]
            },
            {
              title: "shortcut3",
              key: "0-0-0-2",
              _id: "23",
              messages: ["shortcut3 message 1", "shortcut3 message 2"]
            },
          ],
        },
        {
          title: "SUBHEADER 2",
          key: "0-0-1",
          selectable: false,
          children: [
            {
              title: "shortcut4",
              key: "0-0-1-0",
              _id: "24",
              messages: ["shortcut4 message 1"]
            },
          ],
        },
        {
          title: "SUBHEADER 3",
          key: "0-0-2",
          selectable: false,
          children: [
            {
              title: "shortcut5",
              key: "0-0-2-0",
              _id: "25",
              messages: ["shortcut5 message 1", "shortcut5 message 2"]
            },
            {
              title: "shortcut6",
              key: "0-0-2-1",
              _id: "26",
              messages: []
            },
          ],
        },
      ],
    },
    {
      title: "HEADER 2",
      key: "0-1",
      selectable: false,
      children: [
        {
          title: "SUBHEADER 1 ",
          key: "0-1-1",
          selectable: false,
          children: [
            {
              title: "shortcut1",
              key: "0-1-1-0",
              _id: "21",
              messages: ["shortcut1 message 1", "shortcut1 message 2", "shortcut1 message 3"]
            },
            {
              title: "shortcut2",
              key: "0-1-1-5",
              _id: "22",
              messages: ["shortcut2 message 1", "shortcut2 message 2"]
            },
            {
              title: "shortcut3",
              key: "0-1-1-6",
              _id: "23",
              messages: ["shortcut3 message 1", "shortcut3 message 2"]
            },
          ],
        },
        {
          title: "SUBHEADER 2",
          key: "0-1-2",
          selectable: false,
          children: [
            {
              title: "shortcut4",
              key: "0-1-1-7",
              _id: "24",
              messages: ["shortcut4 message 1"]
            },
          ],
        },
        {
          title: "SUBHEADER 3",
          key: "0-1-3",
          selectable: false,
          children: [
            {
              title: "shortcut5",
              key: "0-1-1-8",
              _id: "25",
              messages: ["shortcut5 message 1", "shortcut5 message 2"]
            },
            {
              title: "shortcut6",
              key: "0-1-1-9",
              _id: "26",
              messages: []
            },
          ],
        },
      ],
    },
  ]
 */

const states = {
  canned_response_details: {
    allowed_languages:[],
    canned_responses: [],
    cn_tree: [],
    cn_loading: false,
    cn_load_error: false
  }
}

export default states