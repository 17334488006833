const states = {
  agent_monitoring_details: {
    is_socket_connected: false,

    brand_stats: [],

    groups_stats: [],
    groups_columns: [],
    expanded_group: null,

    agents_stats: [],
    agents_columns: [],
    agents_stats_loading: false,

    selected_agent: null,
    agent_chats: {},
    selected_agent_active_chat_id: null,
    agent_active_chats: {},

    transfer_chat: {
      selected_group: "",
      agents: []
    }
  }
}

export default states
