import states from "./states"
import { BSM_UPDATE_STATE } from "./actiontypes"

const botsuccess_metrics_details = (
  state = states.botsuccess_metrics_details,
  action
) => {
  switch (action.type) {
    case BSM_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default botsuccess_metrics_details