import states from "./states"
import { TEST_RUNS_UPDATE_STATE } from "./actiontypes"

const testruns_details = (state = states.testruns_details, action) => {
  switch (action.type) {
    case TEST_RUNS_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default testruns_details
