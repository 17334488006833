import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button, theme as themeConfig } from "antd"

const ShowMessage = ({
  className,
  style,
  message,
  ImgComponent,
  title,
  btn_text,
  btn_props,
  height,
  width,
  is_internet_connected,
}) => {
  const { token } = themeConfig.useToken()
  const checkInternetConnectAndRenderErrorMessage = () => {
    if (is_internet_connected) {
      return (
        <Fragment>
          <div
            style={{
              marginBottom: token.marginSM,
            }}
          >
            {ImgComponent ? (
              <ImgComponent
                height={height}
                width={width}
                color={
                  process.env.REACT_APP_IMAGE_DEFAULT
                    ? process.env.REACT_APP_IMAGE_DEFAULT
                    : token.colorPrimary
                }
              />
            ) : null}
          </div>
          {title && (
            <p
              className="ori-capitalize-first"
              style={{
                color: token.colorText,
                textAlign: "center",
                fontWeight: token.fontWeightStrong,
                fontSize: token.fontSizeXL,
              }}
            >
              {title}
            </p>
          )}
          {message && (
            <p
              className="ori-capitalize-first"
              style={{
                color: token.colorTextDescription,
                textAlign: "center",
              }}
            >
              {message}
            </p>
          )}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <div
          style={{
            marginBottom: token.marginSM,
          }}
        >
          {ImgComponent ? (
            <ImgComponent
              height="70px"
              width="70px"
              color={
                process.env.REACT_APP_IMAGE_DEFAULT
                  ? process.env.REACT_APP_IMAGE_DEFAULT
                  : token.colorPrimary
              }
            />
          ) : null}
        </div>
        <p
          style={{
            color: token.colorText,
            textAlign: "center",
            fontWeight: token.fontWeightStrong,
            fontSize: token.fontSizeLG,
          }}
        >
          No Internet
        </p>
        <p
          style={{
            color: token.colorTextDescription,
            textAlign: "center",
          }}
        >
          Internet connection has been lost please check your internet
          connection.
        </p>
      </Fragment>
    )
  }

  return (
    <div
      className={`ori-animated ori-fade-in ori-full-parent-height ori-flex-column ori-flex-center ${className}`}
      style={{ padding: token.paddingMD, ...style }}
    >
      {checkInternetConnectAndRenderErrorMessage()}
      {btn_props && (
        <div
          className="ori-flex-row ori-flex-jc "
          style={{
            paddingTop: token.paddingSM,
            paddingBottom: token.paddingSM,
            width: "100%",
          }}
        >
          <Button {...btn_props}>{btn_text}</Button>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    is_internet_connected: state.page_details.is_internet_connected,
  }
}

ShowMessage.propTypes = {
  is_internet_connected: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  btn_text: PropTypes.string,
  btn_props: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
}

ShowMessage.defaultProps = {
  btn_text: "Go Back",
  height: 70,
  width: 70,
  style: {},
  className: "",
}

export default connect(mapStateToProps)(ShowMessage)
