import React, { lazy, Suspense } from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import PropTypes from "prop-types"

import { clearAllDataFromLocalStorage, clearAllDataFromCookies } from "../../../data/utils/dataUtility"

import { IMG_ERROR } from "../../../data/assets"
import { ROUTE_PATH } from "../../../data/constants/layout"
import { isModuleAccessible } from "../../../data/utils/device"
import { log } from "../../../data/utils/common"

const ShowMessage = lazy(() => import("../ShowMessage"))

const ProtectedRoute = ({
  component: Component,
  is_logged_in,
  admin_id,
  grand_parent_path,
  parent_path,
  sibling_path,
  permissions,
  ...rest
}) => {
  log("protected route rendered")

  const isActionPermitted = (key, action) => {
    let isAllowed = true
    if (permissions.actions && permissions.actions[key])
      isAllowed = permissions.actions[key][action]
    return isAllowed
  }

  const isEventPermitted = event => {
    let isAllowed = true
    if (permissions.events)
      isAllowed = permissions.events[event]
    return isAllowed
  }

  const checkIsPathAccessible = path => {
    let is_allowed = true
    if (grand_parent_path && parent_path)
      is_allowed = isModuleAccessible(permissions.features, parent_path, grand_parent_path)
    else if (parent_path)
      is_allowed = isModuleAccessible(permissions.features, path, parent_path)
    else if (sibling_path)
      is_allowed = isModuleAccessible(permissions.features, sibling_path)
    else
      is_allowed = isModuleAccessible(permissions.features, path)
    return is_allowed
  }

  const checkAuthAndRender = props => {
    if (is_logged_in && admin_id) {
      const is_allowed = checkIsPathAccessible(props.match.path)
      if (is_allowed)
        return <Component {...props} isActionPermitted={isActionPermitted} isEventPermitted={isEventPermitted} />
      return (
        <Suspense fallback={null}>
          <ShowMessage
            ImgComponent={IMG_ERROR}
            height={120}
            width={140}
            title="403"
            message="You don't have privileges to access with this credentials, you can contact your administrator."
            btn_props={{ onClick: props.history.goBack }}
          />
        </Suspense>
      )
    } else {
      clearAllDataFromLocalStorage()
      if(process.env.REACT_APP_COOKIE_BASED_LOGIN !== "true") clearAllDataFromCookies()
      return <Redirect to={{ pathname: ROUTE_PATH.LOGIN, state: { from: props.location } }} />
    }
  }

  return <Route {...rest} render={checkAuthAndRender} />
}

const mapStateToProps = state => {
  return {
    permissions: state.admin_details.user_info && state.admin_details.user_info.permissions ? state.admin_details.user_info.permissions : null,
    admin_id: state.admin_details.admin_id,
    is_logged_in: state.admin_details.isLoggedIn
  }
}

ProtectedRoute.propTypes = {
  component: PropTypes.object,
  history: PropTypes.object,
  is_permitted: PropTypes.bool,
  permissions: PropTypes.object,
  is_logged_in: PropTypes.bool,
  admin_id: PropTypes.string,
  parent_path: PropTypes.string,
  sibling_path: PropTypes.string,
  grand_parent_path: PropTypes.string,
}

ProtectedRoute.defaultProps = {
  is_permitted: false,
}

export default connect(mapStateToProps)(ProtectedRoute)
