import states from "./states"
import {
  DL_UPDATE_STATE,
  DL_ADD_MESSAGE,
  DL_UPDATE_MESSAGE,
  DL_DELETE_MESSAGE,
} from "./actiontypes"

const dialogue_details = (state = states.dialogue_details, action) => {
  switch (action.type) {
    case DL_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case DL_ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }

    case DL_UPDATE_MESSAGE: {
      const index = state.messages.findIndex(
        msg => msg.dialogBoxId === action.payload.dialogBoxId
      )
      if (index >= 0)
        return {
          ...state,
          messages: [
            ...state.messages.slice(0, index),
            action.payload,
            ...state.messages.slice(index + 1),
          ],
        }
      return state
    }

    case DL_DELETE_MESSAGE: {
      const index = state.messages.findIndex(
        msg => msg.dialogBoxId === action.payload.dialogBoxId
      )
      if (index >= 0)
        return {
          ...state,
          messages: [
            ...state.messages.slice(0, index),
            ...state.messages.slice(index + 1),
          ],
          ...(state.selected_msg_id === action.payload.dialogBoxId && {
            selected_msg_id: null,
            selected_msg_user_text: "",
            //----- Reset User Utterances -----
            user_utterances: [],
            //------ Reset Predicted Entities ------
            predicted_entities: {},
            //------ Reset Predicted Intents ------
            predicted_intents: [],
            pi_total: 0,
            pi_page_size: 5,
            pi_current_page: 1,
            pi_search_key: "",
            //------ Reset Predicted Actions ------
            predicted_actions: [],
            pa_total: 0,
            pa_page_size: 5,
            pa_current_page: 1,
            pa_search_key: "",
          }),
        }

      return state
    }

    default:
      return state
  }
}

export default dialogue_details
