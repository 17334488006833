import { MODULE_CONFIG } from "../../config/setup"
const states = {
  //variable = v
  botparams_details: {
    variables: [],
    v_loading: false,
    v_load_error: false,
    v_current_page: 1,
    v_page_size: MODULE_CONFIG.BOT_BUILDER.BOT_PARAMS_PER_PAGE_DATA,
    v_total: 0,
    v_search_key: "",
  },
}

export default states
