import * as actionTypes from "./actiontypes"
import states from "./states"

const team_member_setting_details = (state = states.team_member_setting_details, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TEAM_MEMBER_SETTING_STATE:
            return {
                ...state,
                ...action.payload
            }

        case actionTypes.NEW_MEMBERS_ADDED:
            return {
                ...state,
                team_members: [...action.payload, ...state.team_members]
            }

        case actionTypes.REPLACE_MEMBER_OBJECT_IN_TEAM_MEMBERS: {
            let index = -1
            if (action.payload.length > 0)
                index = state.team_members.findIndex(member => member._id === action.payload[0]._id)
            return {
                team_members: index !== -1 ? [...state.team_members.slice(0, index), action.payload[0], ...state.team_members.slice(index + 1)] : [...state.team_members]
            }
        }

        default:
            return state
    }
}

export default team_member_setting_details
