import states from "./states"
import { UPDATE_JOURNEY_STATE } from "./actiontypes"

const users_journeys_details = (
  state = states.users_journeys_details,
  action
) => {
  switch (action.type) {
    case UPDATE_JOURNEY_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default users_journeys_details
