import { MODULE_CONFIG } from "../../config/setup"
import { getDateRange } from "../../utils/common"

const states = {
  usage_details: {
    filter_date_range: getDateRange(),
    filter_platform: "all",
    
    user_engagement_stats: [],
    user_stats_loading: false,
    user_stats_load_error: false,
    
    //================= Chat Sessions ====================
    chat_session_time_series: [],
    chat_session_loading: false,
    chat_session_load_error: false,
    chat_session_granularity: MODULE_CONFIG.GLOBAL.DATE_FILTER.GRANULARITY || "day",
    chat_session_scale: {
      count: {
        min: 0,
      },
      timestamp: {
        type: "time",
        tickInterval: 60 * 60 * 1000 * 24 * 2, // 2 day
        mask: "DD MMM",
      },
    },
    //================= User Retention ===================
    user_retention_cohorts_columns: [],
    user_retention_cohorts_data: [],
    user_retention_granularity: MODULE_CONFIG.GLOBAL.DATE_FILTER.GRANULARITY || "day",
    user_retention_loading: false,
    user_retention_load_error: false,
  },
}

export default states