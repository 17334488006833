import {
  UPDATE_MODEL_ACCURACY_STATE,
  MA_MORE_INTENT_LOADED,
  MA_UPDATE_INTENT,
} from "./actiontypes"
import states from "./states"

import { MODULE_CONFIG } from "../../config/setup"

const model_accuracy_details = (
  state = states.model_accuracy_details,
  action
) => {
  switch (action.type) {
    case UPDATE_MODEL_ACCURACY_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case MA_MORE_INTENT_LOADED:
      return {
        ...state,
        ma_intents: [...state.ma_intents, ...action.payload],
        ma_has_more:
          action.payload.length ===
          MODULE_CONFIG.ANALYTICS.MA_INTENTS_PER_PAGE_DATA,
      }

    case MA_UPDATE_INTENT:
      let index = -1
      index = state.ma_intents.findIndex(
        intent => action.payload.intent === intent.intent
      )
      if (index !== -1 && action?.payload?.count > 0)
        return {
          ...state,
          ma_intents: [
            ...state.ma_intents.slice(0, index),
            {
              ...state.ma_intents[index],
              count: action.payload.count,
            },
            ...state.ma_intents.slice(index + 1),
          ],
        }
      else if (index !== -1 && action?.payload?.count <= 0)
        return {
          ...state,
          ma_intents: [
            ...state.ma_intents.slice(0, index),
            ...state.ma_intents.slice(index + 1),
          ],
        }

      return state

    default:
      return state
  }
}

export default model_accuracy_details
