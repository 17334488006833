import {
  AM_UPDATE_STATE,
  AM_MAKE_SOCKET_CONNECTION,
  AM_CALL_SOCKET_METHOD,
  AM_SOCKET_DISCONNECT,
  AM_EMIT_CUSTOM_EVENT,
  AM_UPDATE_AGENT_CHATS,
  AM_CLOSE_CHAT_WINDOW
} from "./actiontypes"

export const updateAgentMonitoringState = payload => ({
  type: AM_UPDATE_STATE,
  payload
})

export const makeAgentMonitoringSocketConnection = host => ({
  type: AM_MAKE_SOCKET_CONNECTION,
  host
})

export const callAgentMonitoringSocketMethod = payload => ({
  type: AM_CALL_SOCKET_METHOD,
  payload
})

export const agentMonitoringSocketDisconnect = () => ({
  type: AM_SOCKET_DISCONNECT,
})


export const emitAgentMonitoringCustomEvent = (event, payload, callback) => ({
  type: AM_EMIT_CUSTOM_EVENT,
  payload,
  event,
  callback
})

export const updateAgentChats = payload => ({
  type: AM_UPDATE_AGENT_CHATS,
  payload
})

export const closeAgentActiveChatWindow = () => ({
  type: AM_CLOSE_CHAT_WINDOW
})

// ----------------------------- API CALLS --------------------------------

