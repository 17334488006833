const states = {
  domain_details: {
    page_size: 10,

    //slots
    sl_current_page: 1,
    sl_search_key: "",
    sl_total_records: 0,
    sl_loading: false,
    sl_load_error: false,
    sl_records: [],
  },
}

export default states
