const states = {
  testruns_details: {
    testruns_list: [],
    test_results_all: {stats: {} ,pass: [],fail: []},
    selected_testRun: {},
    test_result_details: {},
    loading: false,
    load_error: false,
  },
}

export default states
