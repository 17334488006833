import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"

import { isModuleAccessible } from "../../../data/utils/device"
import { SIDER_MENU } from "../../../data/constants/layout"
import { log } from "../../../data/utils/common"

const ProtectedRedirect = ({
  features,
  parent_path,
  ...rest
}) => {
  log("protected redirect rendered")

  const redirectToAccessibleChild = () => {
    const children = SIDER_MENU.find(item => item.routepath === parent_path).children
    let accessibleChildPath = children[0].routepath
    let index = children.findIndex(child => isModuleAccessible(features, child.routepath, parent_path))
    if (index !== -1)
      accessibleChildPath = children[index].route_path
    return accessibleChildPath
  }

  return (<Redirect {...rest} to={redirectToAccessibleChild()} />)
}

const mapStateToProps = state => {
  return {
    features: state.admin_details.user_info && state.admin_details.user_info.permissions ? state.admin_details.user_info.permissions.features : null,
  }
}

ProtectedRedirect.propTypes = {
  features: PropTypes.object,
  parent_path: PropTypes.string,
}

export default connect(mapStateToProps)(ProtectedRedirect)
