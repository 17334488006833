import states from "./states"
import {
  TKT_UPDATE_STATE,
  TKT_UPDATED,
  TKT_CUSTOMER_PROFILE_RECEIVED,
} from "./actiontypes"

const tickets_details = (state = states.tickets_details, action) => {
  switch (action.type) {
    case TKT_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case TKT_UPDATED: {
      let index = -1
      index = state.tickets.findIndex(
        tkt => tkt.ticketId === action.payload.ticketId
      )
      if (index !== -1)
        return {
          ...state,
          tickets: [
            ...state.tickets.slice(0, index),
            action.payload,
            ...state.tickets.slice(index + 1),
          ],
        }
      return state
    }

    case TKT_CUSTOMER_PROFILE_RECEIVED: {
      if (state.selected_ticket.ticketId === action.payload.ticketId)
        return {
          ...state,
          profile: action.payload.profile,
        }
      return state
    }

    default:
      return state
  }
}

export default tickets_details
