import {
  UPDATE_TEMPLATE_CTA_STATE,
  TC_MORE_TEMPLATE_LOADED,
  TC_CREATED,
  TC_DELETED,
  TC_UPDATED,
} from "./actiontypes"

import states from "./states"
import { MODULE_CONFIG } from "../../config/setup"

const template_cta_details = (state = states.template_cta_details, action) => {
  switch (action.type) {
    case UPDATE_TEMPLATE_CTA_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case TC_MORE_TEMPLATE_LOADED:
      return {
        ...state,
        templates: [...state.templates, ...action.payload],
        tc_has_more:
          action.payload.length ===
          MODULE_CONFIG.WHATSAPP_TEMPLATE.TEMPLATE_CTA_RECORDS_PER_PAGE_DATA,
      }

    case TC_CREATED:
      return {
        ...state,
        templates: [action.payload, ...state.templates],
      }

    case TC_DELETED: {
      let index = -1
      index = state.templates.findIndex(
        template => template.id === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          templates: [
            ...state.templates.slice(0, index),
            ...state.templates.slice(index + 1),
          ],
        }
      return state
    }

    case TC_UPDATED: {
      let index = -1
      index = state.templates.findIndex(
        template => action.payload.id === template.id
      )
      if (index !== -1)
        return {
          ...state,
          templates: [
            ...state.templates.slice(0, index),
            {
              ...state.templates[index],
              ...action.payload,
            },
            ...state.templates.slice(index + 1),
          ],
        }
      return state
    }

    default:
      return state
  }
}
export default template_cta_details
