// const dummy = [
//   {
//     id: "ms212",
//     type: "text",
//     identifier: "Default messages",
//   },
// ]

const states = {
  message_details: {
    //---------------- MESSAGES -----------------
    messages: [],
    loading: false,
    load_error: false,
    search_key: "",
    type: null,
    has_more: false,
    //---------------- EDIT MESSAGES => em -----------------
    selectedMessage: null,
    synced: true,
  },
}

export default states
