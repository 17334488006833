const states = {
    entity_details: {
        entities: [],
        entity_options: [],
        active_entity_data: {},
        entities_loading: false,
        entities_load_error: false,
        entity_loading: false,
        entity_load_error: false
    }
}

export default states
