import states from "./states"
import { MODULE_CONFIG } from "../../config/setup"
import {
  CAT_UPDATE_STATE,
  CAT_MORE_PRODUCTS_LOADED,
  CAT_PRODUCT_CREATED,
  CAT_PRODUCT_UPDATED,
  CAT_PRODUCT_DELETED,
  CAT_CATEGORY_ORDER_UPDATE,
} from "./actiontypes"

const catalogue_details = (state = states.catalogue_details, action) => {
  switch (action.type) {
    case CAT_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case CAT_MORE_PRODUCTS_LOADED:
      return {
        ...state,
        products: [...state.products, ...action.payload],
        has_more:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.CAT_RECORDS_PER_PAGE_DATA,
      }

    case CAT_PRODUCT_CREATED:
      return {
        ...state,
        products: [action.payload, ...state.products],
      }

    case CAT_PRODUCT_UPDATED: {
      let index = -1
      index = state.products.findIndex(
        product => action.payload.pid === product.pid
      )
      if (index !== -1)
        return {
          ...state,
          products: [
            ...state.products.slice(0, index),
            {
              ...state.products[index],
              ...action.payload,
            },
            ...state.products.slice(index + 1),
          ],
        }
      return state
    }
    case CAT_CATEGORY_ORDER_UPDATE: {
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      }
    }

    case CAT_PRODUCT_DELETED: {
      let index = -1
      index = state.products.findIndex(
        product => product.pid === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          products: [
            ...state.products.slice(0, index),
            ...state.products.slice(index + 1),
          ],
        }
      return state
    }

    default:
      return state
  }
}

export default catalogue_details
