import states from './states'
import { UPDATE_EVENT_STATE, EVENT_CREATED, EVENT_UPDATED, EVENT_DELETED } from './actiontypes'

const event_details = (state = states.event_details, action) => {
  switch (action.type) {
    case UPDATE_EVENT_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case EVENT_CREATED: {
      return {
        ...state,
        events: [action.payload, ...state.events],
      }
    }

    case EVENT_UPDATED: {
      const index = state.events.findIndex(event => event.eventId === action.payload.eventId)
      return {
        ...state,
        events: index !== -1 ? [...state.events.slice(0, index), action.payload, ...state.events.slice(index + 1)] : [...state.events],
      }
    }

    case EVENT_DELETED: {
      const index = state.events.findIndex(event => event.eventId === action.payload)
      return {
        ...state,
        events: index !== -1 ? [...state.events.slice(0, index), ...state.events.slice(index + 1)] : [...state.events],
      }
    }

    default:
      return state
  }
}

export default event_details
