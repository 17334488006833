import states from "./states"
import { UPDATE_TAGS_STATE, TAG_CREATED, TAG_UPDATED, TAG_DELETED } from "./actiontypes"

const tags_details = (state = states.tags_details, action) => {
  switch (action.type) {
    case UPDATE_TAGS_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case TAG_CREATED: {
      return {
        ...state,
        tags: [action.payload, ...state.tags]
      }
    }

    case TAG_UPDATED: {
      let index = -1
      index = state.tags.findIndex(tag => tag._id === action.payload._id)
      if (index !== -1)
        return {
          ...state,
          tags: [...state.tags.slice(0, index), action.payload, ...state.tags.slice(index + 1)]
        }
      return state
    }

    case TAG_DELETED: {
      let index = -1
      index = state.tags.findIndex(tag => tag._id === action.payload._id)
      if (index !== -1)
        return {
          ...state,
          tags: [...state.tags.slice(0, index), ...state.tags.slice(index + 1)],
        }
      return state
    }

    default:
      return state
  }
}

export default tags_details
