import { getDateRange } from "../../utils/common"

const histogram_filters_date_range = getDateRange()

const states = {
  event_details: {
    events: [],
    events_loading: false,
    events_load_error: false,
    histogram: [],
    histogram_filters: {
      startDate: histogram_filters_date_range[0],
      endDate: histogram_filters_date_range[1],
    },
  },
}

export default states
