export const loginUser = "/users/login"
export const forgotPassword = "/users/password/forgot"
export const verifyToken = "/users/token/verify"
export const resetPassword = "/users/password/reset"
export const activateUser = "/users/account/verify"
export const resendActivationLink = "/users/email/activation"
export const logOutUser = "/users/logout"
export const updateUserProfile = "/users/admins/profile"
export const updateBifurcation = "users/updateUserFilterSelection"
export const microsoftLogin = "/users/microsoftLogin"
