//TODO: Check configuration_details file and fix it every where
//TODO: SEERIOUS ISSUE: - NEED TO FIX IT ASAP

const states = {
  configuration_details: [],
  isError: false,
  isLoading: false,
}

export default states
