import states from "./states"
import {
  UPDATE_FAQBUILDER_STATE,
  FAQ_MORE_FAQS_LOADED,
  FAQ_UPDATED,
  ADD_NEW_FAQ,
} from "./actiontypes"
import { MODULE_CONFIG } from "../../config/setup"

const faqbuilder_details = (state = states.faqbuilder_details, action) => {
  switch (action.type) {
    case UPDATE_FAQBUILDER_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case FAQ_MORE_FAQS_LOADED:
      return {
        ...state,
        faqs: [...state.faqs, ...action.payload],
        hasMore:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.FAQ_RECORDS_PER_PAGE_DATA,
      }
    case FAQ_UPDATED: {
      const index = state.faqs.findIndex(faq => faq.id === action.payload.id)
      if (index >= 0) {
        return {
          ...state,
          faqs: [
            ...state.faqs.slice(0, index),
            action.payload,
            ...state.faqs.slice(index + 1),
          ],
        }
      }
      return state
    }
    case ADD_NEW_FAQ: {
      const updatedFaqs = [action.payload, ...state.faqs]
      return {
        ...state,
        faqs: updatedFaqs,
      }
    }
    default:
      return state
  }
}
export default faqbuilder_details
