import React from "react"
import PropTypes from "prop-types"
import { Spin, theme as themeConfig } from "antd"

const SpinnerLoader = ({ text, size, container_class, style }) => {
  const { token } = themeConfig.useToken()
  return (
    <div
      style={style}
      className={`ori-full-parent-height ori-flex ori-flex-center ${container_class}`}
    >
      <div
        style={{
          backgroundColor: token.colorBgContainer,
          paddingLeft: token.padding,
          paddingRight: token.padding,
          paddingTop: token.padding,
          paddingBottom: token.paddingSM,
          borderRadius: token.borderRadius,
        }}
      >
        <Spin size={size} />
        {text && (
          <span
            style={{
              marginLeft: token.marginSM,
              color: token.colorPrimary,
              textTransform: "capitalize",
            }}
          >
            {text + "..."}
          </span>
        )}
      </div>
    </div>
  )
}

SpinnerLoader.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(["large", "small", "default"]),
  container_class: PropTypes.string,
  style: PropTypes.object,
}

SpinnerLoader.defaultProps = {
  size: "default",
  container_class: "",
  style: {},
}

export default SpinnerLoader
