import states from './states'
import * as actionTypes from './actiontypes'

const canned_response_details = (state = states.canned_response_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CANNED_RESPONSE_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.CANNED_RESPONSE_CREATED: {
      return {
        ...state,
        canned_responses: [action.payload, ...state.canned_responses]
      }
    }

    case actionTypes.CANNED_RESPONSE_UPDATED: {
      let index = -1;
      index = state.canned_responses.findIndex(response => response._id === action.payload._id);
      return {
        ...state,
        canned_responses: index !== -1 ? [...state.canned_responses.slice(0, index), action.payload, ...state.canned_responses.slice(index + 1)] : [...state.canned_responses]
      };
    }

    case actionTypes.CANNED_RESPONSE_DELETED: {
      let index = -1;
      index = state.canned_responses.findIndex(response => response._id === action.payload._id);
      return {
        ...state,
        canned_responses: index !== -1 ? [...state.canned_responses.slice(0, index), ...state.canned_responses.slice(index + 1)] : [...state.canned_responses]
      };
    }

    default:
      return state
  }
}

export default canned_response_details
