import states from "./states"
import { UPDATE_FRACTURE_STATE } from './actiontypes'

const fracture_details = (state = states.fracture_details, action) => {
  switch (action.type) {
    case UPDATE_FRACTURE_STATE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default fracture_details
