import { checkDevice } from "../../utils/device"
import { getDataFromLocalStorage } from "../../utils/dataUtility"
import { LOCAL_STORAGE } from "../../constants/common"

const states = {
  page_details: {
    theme: getDataFromLocalStorage(LOCAL_STORAGE.THEME, "light"),
    is_internet_connected: navigator.onLine,
    checking_internet: false,
    lang: "en",
    device_data: checkDevice.deviceStatus(),
    page_title: "",
    current_page: "",
    current_sider_module: "",
    roles: [],
    allowedMessageVariations: {},
    defaultMessageVariations: {
      channel: { label: "website", value: "web" },
      country: { label: "India", value: "in" },
      language: { label: "English", value: "en" },
      param1: { label: "opt1", value: "param1|opt1" },
    },
    preemptive_data: {
      actions: [],
      variables: [],
      messages: [],
      event_tags: [],
      event_types: [],
      agent_states: [],
      workflows: [],
    },
    chatlogs_filters: [],
    modal_loading: false,
    page_loading: false,
    page_loading_text: "",
    bot_training: false,
    bot_publishing: false,
    wa_file_uploading: false,

    popup_loading: false, //TODO: remove this and use modal_loading
    maintenanceAlertData: {},
  },
}

export default states
