import {io} from "socket.io-client"

import { updateDialogueState } from "./actions"
import {
  DL_MAKE_SOCKET_CONNECTION,
  DL_CALL_SOCKET_METHOD,
  DL_SOCKET_DISCONNECT,
  DL_EMIT_CUSTOM_EVENT,
  DL_ADD_MESSAGE,
  DL_UPDATE_MESSAGE,
} from "./actiontypes"

import { getAuthSocketData } from "../../utils/dataUtility"
import { SOCKET_EVENTS } from "../../constants/socket"
import { log } from "../../utils/common"

const registerSocketListener = (store, socket) => {
  socket.on(SOCKET_EVENTS.CONNECT, () => {
    log("dialogue socket connected")
    store.dispatch(
      updateDialogueState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
    store.dispatch(
      updateDialogueState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.ERROR, () => {
    store.dispatch(
      updateDialogueState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.DISCONNECT, reason => {
    log("dialogue socket disconnected")
    if (reason === "io server disconnect") socket.connect()
    store.dispatch(
      updateDialogueState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.ADDITION, res => {
    if (res.type === "newMessage" && res.data && res.data.newMessage) {
      store.dispatch({
        type: DL_ADD_MESSAGE,
        payload: res.data.newMessage,
      })
    }
  })

  socket.on(SOCKET_EVENTS.UPDATION, res => {
    if (res.type === "updateMessage" && res?.data?.message?.dialogBoxId) {
      store.dispatch({
        type: DL_UPDATE_MESSAGE,
        payload: res.data.message,
      })
    } else if (res.type === "matchDialogues" && res?.data?.matchDialogues) {
      store.dispatch(
        updateDialogueState({
          match_dialogues: res.data.matchDialogues,
        })
      )
    } else if (res.type === "inputLock" && res?.data) {
      store.dispatch(updateDialogueState({ input_lock: res.data.inputLock }))
    } else if (res.type === "updateGlobalSync" && res.data) {
      store.dispatch(updateDialogueState({ is_sync: res.data.isSync }))
    } else {
      const selected_msg_id = store.getState().dialogue_details.selected_msg_id
      if (
        selected_msg_id &&
        res?.data?.dialogBoxId &&
        selected_msg_id === res.data.dialogBoxId
      ) {
        if (
          res.type === "predictedIntents" &&
          res?.data?.predictedIntents &&
          res?.data?.totalCount !== undefined
        ) {
          store.dispatch(
            updateDialogueState({
              predicted_intents: res.data.predictedIntents,
              pi_total: res.data.totalCount,
            })
          )
        } else if (
          res.type === "predictedEntities" &&
          res?.data?.predictedEntities
        ) {
          store.dispatch(
            updateDialogueState({
              predicted_entities: res.data.predictedEntities,
            })
          )
        } else if (
          res.type === "predictedActions" &&
          res?.data?.predictedActions &&
          res?.data?.totalCount !== undefined
        ) {
          store.dispatch(
            updateDialogueState({
              predicted_actions: res.data.predictedActions,
              pa_total: res.data.totalCount,
            })
          )
        } else if (res.type === "utterance" && res?.data?.utterances) {
          store.dispatch(
            updateDialogueState({
              user_utterances: res.data.utterances,
            })
          )
        }
      }
    }
  })
}

const middleware = () => {
  let socket = null
  return store => next => action => {
    switch (action.type) {
      case DL_MAKE_SOCKET_CONNECTION:
        if (socket !== null) socket.close()
        const AUTH_SOCKET_DATA = getAuthSocketData()
        fetch("https://api64.ipify.org?format=json")
          .then(response => response.json())
          .then(data => {
            AUTH_SOCKET_DATA.query.publicIP = data.ip
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
          .catch(() => {
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
        break

      case DL_CALL_SOCKET_METHOD:
        if (socket) socket[action.payload]()
        break

      case DL_EMIT_CUSTOM_EVENT:
        if (socket && action.event) {
          if (action.callback)
            socket.emit(action.event, action.payload, action.callback)
          else socket.emit(action.event, action.payload)
        }
        break

      case DL_SOCKET_DISCONNECT:
        if (socket !== null) socket.close()
        socket = null
        break

      default:
        return next(action)
    }
  }
}

export default middleware()
