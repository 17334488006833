import {
    UPDATE_ENTITY_STATE,
    ENTITY_CREATED,
    ENTITY_UPDATED,
    ENTITY_DELETED
} from './actiontypes'
import states from './states'

const entity_details = (state = states.entity_details, action) => {
    switch (action.type) {
        case UPDATE_ENTITY_STATE:
            return {
                ...state,
                ...action.payload
            }

        case ENTITY_CREATED:
            return {
                ...state,
                entities: [action.payload, ...state.entities]
            }

        case ENTITY_UPDATED: {
            const index = state.entities.findIndex(entity => entity.entityID === action.payload.entityID)
            return {
                ...state,
                entities: index !== -1 ? [...state.entities.slice(0, index), action.payload, ...state.entities.slice(index + 1)] : [...state.entities]
            }
        }

        case ENTITY_DELETED:
            const index = state.entities.findIndex((entity) => {
                return (entity.entityID === action.payload)
            })
            return {
                ...state,
                entities: index !== -1 ? [...state.entities.slice(0, index), ...state.entities.slice(index + 1)] : [...state.entities]
            }

        default:
            return state
    }
}

export default entity_details
