import * as actionTypes from './actiontypes'
import states from './states'

const conversion_details = (state = states.conversion_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONVERSION_STATE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default conversion_details
