import states from "./states"
import {
  TICKET_HISTORY_UPDATE_STATE,
  TICKETLOGS_LOADED,
  UPDATE_SELECTED_TICKET,
  MORE_TICKETLOGS_LOADED,
  UPDATE_TICKET_LIVE_CHATS,
  REMOVE_TICKET_LIVE_CHATS,
  REMOVE_LIVE_CHATS_ON_SELECTION,
} from "./actiontypes"
import { MODULE_CONFIG } from "../../config/setup"

const ticket_history_details = (
  state = states.ticket_history_details,
  action
) => {
  switch (action.type) {
    case TICKET_HISTORY_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case TICKETLOGS_LOADED: {
      let index = -1
      if (
        state.selected_ticket.ticket_item &&
        state.selected_ticket.ticket_item.ticketId
      ) {
        index = action.payload.tickets.findIndex(
          item => item.ticketId === state.selected_ticket.ticket_item.ticketId
        )
      }
      const ticket_list_count = action.payload.tickets.filter(
        ticket => !ticket.hasOwnProperty("chatTicketId")
      ).length

      return {
        ...state,
        ticket_list: action.payload.tickets,
        load_more:
          action.payload.tickets.length ===
          MODULE_CONFIG.TICKET_HISTORY.LOAD_MORE_TICKETLOGS_COUNT,
        total_count: action.payload.totalCount ? action.payload.totalCount : 0,
        ticket_list_count,
        selected_ticket:
          index === -1
            ? states.ticket_history_details.selected_ticket
            : { ...state.selected_ticket },
      }
    }

    case UPDATE_SELECTED_TICKET: {
      if (
        state.selected_ticket.ticket_item &&
        state.selected_ticket.ticket_item.ticketId === action.payload.ticketId
      ) {
        if (action.payload.key)
          return {
            ...state,
            selected_ticket: {
              ...state.selected_ticket,
              [action.payload.key]: {
                ...state.selected_ticket[action.payload.key],
                ...action.payload.value,
              },
            },
          }
        else
          return {
            ...state,
            selected_ticket: {
              ...state.selected_ticket,
              ...action.payload.changedValue,
            },
          }
      } else return state
    }

    case UPDATE_TICKET_LIVE_CHATS: {
      const liveChats = action.payload.selected_ticket?.liveChats
      const ticketIdToUpdate = action.payload.ticketId

      if (
        state.selected_ticket.ticket_item &&
        state.selected_ticket.ticket_item.ticketId === ticketIdToUpdate &&
        liveChats
      ) {
        const updatedTicketIndex = state.ticket_list.findIndex(
          ticket => ticket.ticketId === ticketIdToUpdate
        )
        if (updatedTicketIndex !== -1) {
          const hasDuplicateLiveChats = liveChats.some(newChat =>
            state.ticket_list.some(
              existingTicket => existingTicket.sessionId === newChat.sessionId
            )
          )
          if (!hasDuplicateLiveChats) {
            const updatedLiveChats = liveChats.map(chat => ({
              ...chat,
              chatTicketId: ticketIdToUpdate,
            }))
            const updatedTicketList = [
              ...state.ticket_list.slice(0, updatedTicketIndex + 1),
              ...updatedLiveChats,
              ...state.ticket_list.slice(updatedTicketIndex + 1),
            ]
            const ticket_list_count = state.ticket_list.filter(
              ticket => !ticket.hasOwnProperty("chatTicketId")
            ).length

            return {
              ...state,
              ticket_list: updatedTicketList,
              selected_ticket: {
                ...state.selected_ticket,
                liveChats: updatedLiveChats,
              },
              ticket_list_count,
            }
          }
        }
      }

      return state
    }

    case REMOVE_TICKET_LIVE_CHATS: {
      const sessionIdsToRemove = action.payload.selected_ticket_livechats.map(
        chat => chat.sessionId
      )
      const filteredTicketList = state.ticket_list.filter(
        chat => !sessionIdsToRemove.includes(chat.sessionId)
      )

      return {
        ...state,
        ticket_list: filteredTicketList,
      }
    }

    case REMOVE_LIVE_CHATS_ON_SELECTION: {
      let updatedTicketList = state.ticket_list
        .map(ticket => {
          if (
            ticket.hasOwnProperty("chatTicketId") &&
            ticket.chatTicketId !== state.selected_ticket_id
          ) {
            return null
          }

          return ticket
        })
        .filter(ticket => ticket !== null)

      return {
        ...state,
        ticket_list: updatedTicketList,
      }
    }

    case MORE_TICKETLOGS_LOADED: {
      const updatedTicketList = [
        ...state.ticket_list,
        ...action.payload.tickets,
      ]
      const ticket_list_count = updatedTicketList.filter(
        ticket => !ticket.hasOwnProperty("chatTicketId")
      ).length
      return {
        ...state,
        ticket_list: updatedTicketList,
        load_more:
          action.payload.tickets.length ===
          MODULE_CONFIG.TICKET_HISTORY.LOAD_MORE_TICKETLOGS_COUNT,
        ticket_list_count,
      }
    }

    default:
      return state
  }
}

export default ticket_history_details
