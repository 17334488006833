import { MODULE_CONFIG } from "../../config/setup"
import {
  QA_UPDATE_STATE,
  QA_UPDATE_INTENT,
  QA_LOAD_CLUSTER_REQUESTS,
} from "./actiontypes"
import states from "./states"

const query_details = (state = states.query_details, action) => {
  switch (action.type) {
    case QA_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case QA_UPDATE_INTENT: {
      let index = -1
      index = state.intent_records.findIndex(
        intent => intent.id === action.payload.id
      )
      if (index >= 0)
        return {
          ...state,
          intent_records: [
            ...state.intent_records.slice(0, index),
            action.payload,
            ...state.intent_records.slice(index + 1),
          ],
        }
      return state
    }

    case QA_LOAD_CLUSTER_REQUESTS: {
      return {
        ...state,
        cluster_requests: [
          ...state.cluster_requests,
          ...action.payload.clusters,
        ],
        cr_load_more: action.payload.clusters.length === MODULE_CONFIG.QUERY_ANALYTICS.LOAD_MORE_CLUSTER_REQUESTS,
      }
    }

    default:
      return state
  }
}

export default query_details
