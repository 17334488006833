import {io} from "socket.io-client"

import { updateDashboardState } from "./actions"
import {
  DASHBOARD_MAKE_SOCKET_CONNECTION,
  CALL_DASHBOARD_SOCKET_METHOD,
  EMIT_DASHBOARD_CUSTOM_EVENT,
  DASHBOARD_SOCKET_DISCONNECT,
  LIVE_COUNT_PER_SEC,
  LIVE_COUNT_PER_MIN,
} from "./actiontypes"

import { getAuthSocketData } from "../../utils/dataUtility"
import { SOCKET_EVENTS } from "../../constants/socket"
import { log } from "../../utils/common"

const registerSocketListener = (store, socket) => {
  socket.on(SOCKET_EVENTS.CONNECT, () => {
    store.dispatch(
      updateDashboardState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
    store.dispatch(
      updateDashboardState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.ERROR, () => {
    store.dispatch(
      updateDashboardState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.DISCONNECT, reason => {
    if (reason === "io server disconnect") socket.connect()
    store.dispatch(
      updateDashboardState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.PER_SECOND_STATS, response => {
    log("PER_SECOND_STATS", response)
    if (response && response.liveCount && response.liveCount.length > 0)
      store.dispatch({
        type: LIVE_COUNT_PER_SEC,
        payload: response.liveCount,
      })
  })

  socket.on(SOCKET_EVENTS.PER_MINUTE_STATS, response => {
    log("PER_MINUTE_STATS", response)
    if (response && response.liveCount && response.liveCount.length > 0)
      store.dispatch({
        type: LIVE_COUNT_PER_MIN,
        payload: response.liveCount,
      })
  })

  socket.on(SOCKET_EVENTS.LAST_N_HOURS_TIME_SERIES, response => {
    log("LAST_N_HOURS_TIME_SERIES", response)
    if (response && response.timeSeries && response.timeSeries.length > 0)
      store.dispatch(
        updateDashboardState({ live_traffic_per_min: response.timeSeries })
      )
  })
}

const middleware = () => {
  let socket = null
  return store => next => action => {
    switch (action.type) {
      case DASHBOARD_MAKE_SOCKET_CONNECTION: {
        if (socket !== null) socket.close()
        const AUTH_SOCKET_DATA = getAuthSocketData()
        fetch("https://api64.ipify.org?format=json")
          .then(response => response.json())
          .then(data => {
            AUTH_SOCKET_DATA.query.publicIP = data.ip
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
          .catch(() => {
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
        break
      }

      case CALL_DASHBOARD_SOCKET_METHOD:
        if (socket) socket[action.payload]()
        break

      case EMIT_DASHBOARD_CUSTOM_EVENT: {
        if (socket && action.event) {
          log(`emit event ${action.event}`, action.payload)
          if (action.callback)
            socket.emit(action.event, action.payload, action.callback)
          else socket.emit(action.event, action.payload)
        }
        break
      }

      case DASHBOARD_SOCKET_DISCONNECT:
        if (socket !== null) socket.close()
        socket = null
        break

      default:
        return next(action)
    }
  }
}

export default middleware()