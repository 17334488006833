import states from "./states"
import { KPI_UPDATE_STATE } from "./actiontypes"

const kpi_details = (state = states.kpi_details, action) => {
  switch (action.type) {
    case KPI_UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default kpi_details
