import React from "react"
const Chatting = ({height, width, color}) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={width}
    height={height}
    viewBox="0 0 725.85572 493.82563"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M246.72509,241.01744H435.27491a9.66388,9.66388,0,0,1,9.653,9.653V530.25986a9.66387,9.66387,0,0,1-9.653,9.653H246.72509a9.66387,9.66387,0,0,1-9.653-9.653V250.67039A9.66388,9.66388,0,0,1,246.72509,241.01744Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#f2f2f2"
    />
    <path
      d="M249.0652,248.03777H432.9348a4.97829,4.97829,0,0,1,4.97273,4.97273V527.91976a4.97829,4.97829,0,0,1-4.97273,4.97273H249.0652a4.97829,4.97829,0,0,1-4.97273-4.97273V253.0105A4.97829,4.97829,0,0,1,249.0652,248.03777Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#fff"
    />
    <path
      d="M376.84252,350.71712c0,17.67311-16.34161,15-36.5,15s-36.5,2.67311-36.5-15,7.98246-49,36.5-49C369.84252,301.71712,376.84252,333.044,376.84252,350.71712Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <path
      d="M419.7806,500.31525a10.74272,10.74272,0,0,0-6.25282-15.23974l-41.81616-88.45906-19.12793,13.413,47.1186,82.73471a10.80091,10.80091,0,0,0,20.07831,7.551Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#a0616a"
    />
    <path
      d="M383.3937,404.38708l-2.39014-3.74-1.21972-1.92-9.39014-14.72a13.3748,13.3748,0,0,0-24.45019,10.85l2.15039,7.67,4.38964,15.68a4.70061,4.70061,0,0,0,1.62012,2.44,4.37247,4.37247,0,0,0,.98.62,4.79238,4.79238,0,0,0,3.93994.07c.02-.01.0503-.02.07032-.03l11.35986-5.08.02-.01,8.66992-3.88,2.16016-.96a4.70689,4.70689,0,0,0,1.62011-1.19,4.65015,4.65015,0,0,0,1.01954-1.83A4.756,4.756,0,0,0,383.3937,404.38708Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M323.46353,529.80712a4.82146,4.82146,0,0,0-8.21-.08,3.92072,3.92072,0,0,0-.4497.92l-.57032,1.57h10.68018Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <circle cx="103.0406" cy="137.0544" r="24.56103" fill="#a0616a" />
    <path
      d="M383.16372,412.7871c-.06006-.88-.14014-1.75-.23975-2.6a54.597,54.597,0,0,0-1.92041-9.54c-.18994-.68-.3999-1.34-.62988-1.99a53.311,53.311,0,0,0-14.75976-22.44,14.57376,14.57376,0,0,0-9.73-3.72h-34.6001a14.58343,14.58343,0,0,0-14.37012,17.06c-.01025.01-.01025.01,0,.02a14.4943,14.4943,0,0,0,1.33008,4.02l1.3999,2.79,9.37988,18.76,11.66993,23.34.68017,1.36,43.49024,11.96.17968-.41c.16016-.36.33008-.73.48-1.09.24024-.54.47022-1.08.7002-1.61a85.24335,85.24335,0,0,0,6.96-27.81v-.01A64.10078,64.10078,0,0,0,383.16372,412.7871Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <path
      d="M376.22378,448.69708a4.67431,4.67431,0,0,0-.68994-1.42,18.6942,18.6942,0,0,0-3.97022-3.92l-.00976-.01c-5.27-4-15.73-8.66-35.93994-8.78h-.03028a4.82651,4.82651,0,0,0-4.39013,2.83l-.5,1.09-3.21973,7.07995a3.73082,3.73082,0,0,1-1.25,1.51c-5.10986,3.62-29.85986,23.92005-15.33008,68.49,1.41992,4.35,2.87988,9.1,4.35986,14.16q.36036,1.23.72022,2.49h43.56006l-.93018-7a3.70677,3.70677,0,0,1,.07031-1.37l17.52979-72.34.08008-.31A4.81675,4.81675,0,0,0,376.22378,448.69708Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <path
      d="M389.89371,367.71709l19,0a3.00006,3.00006,0,0,1,3,3v5a1.00307,1.00307,0,0,1,1,1v2a1.00308,1.00308,0,0,1-1,1v2a1.00308,1.00308,0,0,1,1,1v2a1.00308,1.00308,0,0,1-1,1l0,27a3.00007,3.00007,0,0,1-3,3l-19,0a3.00007,3.00007,0,0,1-3-3l0-42A3.00008,3.00008,0,0,1,389.89371,367.71709Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <path
      d="M388.89369,412.7171v-42a3.00007,3.00007,0,0,1,3-3h-2a3.00007,3.00007,0,0,0-3,3v42a3.00007,3.00007,0,0,0,3,3h2A3.00007,3.00007,0,0,1,388.89369,412.7171Z"
      transform="translate(-237.07214 -203.08719)"
      fill={color}
    />
    <circle cx="161.82156" cy="178.62991" r="4" fill="#fff" />
    <path
      d="M393.81854,395.7304a10.52584,10.52584,0,0,0-.86435,1.41428l-49.23923,5.915-7.24081-9.62939-15.96072,9.02824,11.16177,18.29267a8,8,0,0,0,8.91848,3.55538l54.46327-14.73582a10.4971,10.4971,0,1,0-1.23841-13.8404Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#a0616a"
    />
    <path
      d="M343.30386,396.89709l-13.8003-17.88a12.49724,12.49724,0,0,0-22.58984,10.54c-.01025.01-.01025.01,0,.02a11.3634,11.3634,0,0,0,.73975,1.58l1.99023,5.23,6.04981,15.95a4.47813,4.47813,0,0,0,2.67041,2.63,4.04234,4.04234,0,0,0,.65966.18,4.45217,4.45217,0,0,0,3.07032-.48l4.37988-2.45,15.46-8.64a4.55186,4.55186,0,0,0,2.21-3.03A4.50776,4.50776,0,0,0,343.30386,396.89709Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <path
      d="M311.84252,344.71706v0h9.71436l4.28564-14.76922.85693,14.76922h4.64307l2.5-8.61538.5,8.61538h34.5v0c0-17.67308-11.64059-31.99994-26-31.99994h-5.00006C323.4831,312.71712,311.84252,327.044,311.84252,344.71706Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <path
      d="M596.71993,325.9429H393.13046a9.66387,9.66387,0,0,1-9.653-9.653V212.74014a9.66387,9.66387,0,0,1,9.653-9.653H596.71993a9.66386,9.66386,0,0,1,9.653,9.653V316.28995A9.66386,9.66386,0,0,1,596.71993,325.9429Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M594.37983,318.92258H395.47057a4.97829,4.97829,0,0,1-4.97273-4.97273V215.08024a4.97829,4.97829,0,0,1,4.97273-4.97273H594.37983a4.97829,4.97829,0,0,1,4.97273,4.97273v98.86961A4.9783,4.9783,0,0,1,594.37983,318.92258Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#fff"
    />
    <path
      d="M405.33688,344.97693a3.75017,3.75017,0,0,1-1.43786-.2908,3.63886,3.63886,0,0,1-2.28169-3.41476V322.23971l28.68-.725-22.37044,22.37015A3.6456,3.6456,0,0,1,405.33688,344.97693Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M570.16142,260.08131H469.53673a4.68022,4.68022,0,1,1,0-9.36043H570.16142a4.68021,4.68021,0,0,1,0,9.36043Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M537.39989,240.19039H469.53673a4.68022,4.68022,0,1,1,0-9.36044h67.86316a4.68022,4.68022,0,0,1,0,9.36044Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <circle cx="190.81037" cy="42.36845" r="14.62568" fill={color} />
    <circle cx="302.07968" cy="90.43273" r="4.68022" fill="#e6e6e6" />
    <circle cx="318.46044" cy="90.43273" r="4.68022" fill="#e6e6e6" />
    <circle cx="334.8412" cy="90.43273" r="4.68022" fill="#e6e6e6" />
    <path
      d="M764.72509,398.01744H953.27491a9.66388,9.66388,0,0,1,9.653,9.65295V687.25986a9.66387,9.66387,0,0,1-9.653,9.653H764.72509a9.66387,9.66387,0,0,1-9.653-9.653V407.67039A9.66388,9.66388,0,0,1,764.72509,398.01744Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#f2f2f2"
    />
    <path
      d="M767.0652,405.03777H950.9348a4.97829,4.97829,0,0,1,4.97273,4.97273V684.91976a4.97829,4.97829,0,0,1-4.97273,4.97273H767.0652a4.97829,4.97829,0,0,1-4.97273-4.97273V410.0105A4.97829,4.97829,0,0,1,767.0652,405.03777Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#fff"
    />
    <path
      d="M772.64477,539.57281H569.05529a9.66387,9.66387,0,0,1-9.653-9.653V426.37005a9.66387,9.66387,0,0,1,9.653-9.65295H772.64477a9.66387,9.66387,0,0,1,9.653,9.65295V529.91986A9.66387,9.66387,0,0,1,772.64477,539.57281Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M770.30466,532.55249H571.3954a4.97829,4.97829,0,0,1-4.97273-4.97273V428.71015a4.97829,4.97829,0,0,1,4.97273-4.97273H770.30466a4.97829,4.97829,0,0,1,4.97273,4.97273v98.86961A4.97829,4.97829,0,0,1,770.30466,532.55249Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#fff"
    />
    <path
      d="M758.78529,557.51477l-22.37043-22.37015,28.68.725v19.03166a3.63885,3.63885,0,0,1-2.28169,3.41476,3.75017,3.75017,0,0,1-1.43786.2908A3.64561,3.64561,0,0,1,758.78529,557.51477Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M746.08625,473.71122H645.46157a4.68022,4.68022,0,1,1,0-9.36043H746.08625a4.68022,4.68022,0,1,1,0,9.36043Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <path
      d="M713.32473,453.8203H645.46157a4.68022,4.68022,0,1,1,0-9.36044h67.86316a4.68022,4.68022,0,0,1,0,9.36044Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ccc"
    />
    <circle cx="366.7352" cy="255.99836" r="14.62568" fill={color} />
    <circle cx="478.00451" cy="304.06264" r="4.68022" fill="#e6e6e6" />
    <circle cx="494.38527" cy="304.06264" r="4.68022" fill="#e6e6e6" />
    <circle cx="510.76604" cy="304.06264" r="4.68022" fill="#e6e6e6" />
    <path
      d="M774.88126,602.2472a10.05579,10.05579,0,0,1,15.32038-1.74408L821.95606,584.113l-1.974,18.46448L790.13014,615.311a10.11027,10.11027,0,0,1-15.24888-13.06381Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ffb8b8"
    />
    <path
      d="M873.75356,520.1571a14.50022,14.50022,0,0,0-17.35009-.39,15.0208,15.0208,0,0,0-3.04981,2.92l-27.87988,51.71-25.73,19.44a4.48714,4.48714,0,0,0-1.68018,2.61,4.4321,4.4321,0,0,0,.08985,2.31l2.53027,8.16a4.53909,4.53909,0,0,0,1.52,2.22,5.10989,5.10989,0,0,0,.8999.54.61567.61567,0,0,0,.12012.05,4.47,4.47,0,0,0,3.5498-.01l30.16016-13.08.33008-.15a46.33092,46.33092,0,0,0,23.12988-22.55005l15.85986-33.47A14.5129,14.5129,0,0,0,873.75356,520.1571Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <circle cx="639.87739" cy="273.45375" r="24.56103" fill="#ffb8b8" />
    <path
      d="M893.57388,640.7871l-.25-1.91-1.16016-8.71-1.27-9.51-4.50976.77-1.74024.29-1.75976.3-.58008.1-44.74024,7.6-5.56982-3.35-.12012-.07.05029.51,1.04981,10.89,5.00976,52.02h62.12012Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <path
      d="M910.77358,540.85711a34.65261,34.65261,0,0,0-11.75-23.04,32.69863,32.69863,0,0,0-23.6499-8.16c-8.25.55-14.58984,3.94-18.97021,10.11a34.64244,34.64244,0,0,0-4.91993,10.84c-4.98,17.92-10.23,44.17-14.21972,65.88-2.24024,12.12-4.08008,22.82995-5.27,29.88-.02.15-.04.29-.06983.44-.46044,2.75-.81005,4.9-1.04,6.33a4.484,4.484,0,0,0,2.08984,4.56c.00976.01.02978.02.04.03a33.07446,33.07446,0,0,0,16.68994,4.69c7.12012,0,15.66016-2.48,24.6499-10.49a3.52434,3.52434,0,0,1,4.51026-.19l.98.76,9.67968,7.53a4.48737,4.48737,0,0,0,2.64014.95,4.00538,4.00538,0,0,0,1.41016-.19.524.524,0,0,0,.11963-.03,4.47747,4.47747,0,0,0,2.92041-3.03c.53955-1.88,1.13964-4.05,1.7998-6.47.27-1.00994.54981-2.07.83984-3.17,1.27-4.86,2.68018-10.5,4.06006-16.64C908.13394,589.98712,912.71353,562.41711,910.77358,540.85711Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <path
      d="M870.3269,643.35286a10.05578,10.05578,0,0,1,10.97879-10.8269l14.87184-32.49319,9.84155,15.74731-15.63137,28.44185a10.11027,10.11027,0,0,1-20.06081-.86907Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#ffb8b8"
    />
    <path
      d="M920.1437,568.30712l-8.18017-36.13a14.52959,14.52959,0,0,0-12.93995-14.36c-.50976-.05-1.02978-.07995-1.56005-.07995a14.52756,14.52756,0,0,0-14.48975,14.59l9.98,57.89-.02979.12-5.29,19.97-2.93018,11.09a1.64643,1.64643,0,0,0-.06006.32,4.33628,4.33628,0,0,0,.3501,2.75,4.43052,4.43052,0,0,0,1.5,1.78l5.66992,3.92,1.3501.94a4.45753,4.45753,0,0,0,2.56006.8,4.6959,4.6959,0,0,0,1.0498-.12c.04-.01.06983-.02.10986-.03a4.412,4.412,0,0,0,1.18018-.5,4.4907,4.4907,0,0,0,1.62012-1.69995l15.65967-28.91A46.431,46.431,0,0,0,920.1437,568.30712Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <polygon
      points="647.893 353.651 656.893 391.651 643.893 423.651 652.893 392.651 647.893 353.651"
      opacity="0.2"
    />
    <path
      d="M896.82726,491.30225a16.53848,16.53848,0,0,0,5.56792-3.3377c.71476-.63818,1.483-1.231,2.19616-1.86881,6.298-5.63286,8.19023-15.31969,5.38028-23.28825s-9.79872-14.09376-17.78456-16.85421-16.83725-2.38366-24.94582-.00778c-8.73428,2.5592-17.48157,8.322-19.55612,17.18386-.55221,2.3589-.57321,4.97583,1.03307,6.96573a4.40362,4.40362,0,0,0,1.834,1.34478c2.83043,1.085,4.40754-1.12983,6.5201-2.07055,2.90369-1.293,6.47739.10319,8.4986,2.55635s2.76949,5.71154,3.07979,8.87493a23.45894,23.45894,0,0,0,.91072,5.75968,5.74057,5.74057,0,0,0,4.01072,3.88868c2.27844.42089,4.46958-1.14791,5.903-2.96825s2.44959-3.98248,4.14351-5.56331,4.45662-2.41517,6.3133-1.02912a6.93481,6.93481,0,0,1,1.55,1.86108c2.84585,4.37141,2.49942,4.18148,5.34527,8.55289Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#2f2e41"
    />
    <path
      d="M779.20559,581.2194,761.87847,589.015a3.00007,3.00007,0,0,0-1.505,3.96674l2.05146,4.55977a1.00308,1.00308,0,0,0-.50166,1.32224l.82059,1.82391a1.00308,1.00308,0,0,0,1.32225.50166l.82058,1.82391a1.00307,1.00307,0,0,0-.50166,1.32225l.82058,1.8239a1.00308,1.00308,0,0,0,1.32225.50167l11.07789,24.62276a3.00007,3.00007,0,0,0,3.96674,1.505l17.32713-7.79555a3.00007,3.00007,0,0,0,1.505-3.96674l-17.23228-38.30207A3.00007,3.00007,0,0,0,779.20559,581.2194Z"
      transform="translate(-237.07214 -203.08719)"
      fill="#3f3d56"
    />
    <path
      d="M797.19351,618.76708l-2.43995-5.43-6.3999-14.22-5.62012-12.49a2.87811,2.87811,0,0,0-3.7998-1.45l-9.54,4.29-4.37012,1.97a2.88273,2.88273,0,0,0-1.43994,3.80005l5.81006,12.92,2.23,4.95,6.41992,14.28a2.89061,2.89061,0,0,0,3.81006,1.44l13.8999-6.26A2.879,2.879,0,0,0,797.19351,618.76708Z"
      transform="translate(-237.07214 -203.08719)"
      fill={color}
    />
  </svg>
}

export default Chatting
