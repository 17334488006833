import states from './states'
import { UPDATE_DASHBOARD_STATE, LIVE_COUNT_PER_SEC, LIVE_COUNT_PER_MIN } from './actiontypes'

const dashboard_details = (state = states.dashboard_details, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case LIVE_COUNT_PER_SEC: {
      return {
        ...state,
        live_count: [...action.payload],
        live_traffic_per_sec: state.live_traffic_per_sec.length >= (60 * action.payload.length) ?
          [...state.live_traffic_per_sec.slice(action.payload.length), ...action.payload] :
          [...state.live_traffic_per_sec, ...action.payload],
      }
    }

    case LIVE_COUNT_PER_MIN: {
      return {
        ...state,
        live_traffic_per_min: state.live_traffic_per_min.length >= (60 * action.payload.length) ?
          [...state.live_traffic_per_min.slice(action.payload.length), ...action.payload] :
          [...state.live_traffic_per_min, ...action.payload]
      }
    }

    default:
      return state
  }
}

export default dashboard_details
