import states from "./states"
import {
  MSG_UPDATE_STATE,
  MSG_MORE_MESSAGES_LOADED,
  MSG_DELETED,
} from "./actiontypes"
import { MODULE_CONFIG } from "../../config/setup"

const message_details = (state = states.message_details, action) => {
  switch (action.type) {
    case MSG_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case MSG_MORE_MESSAGES_LOADED:
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
        has_more:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.MSG_RECORDS_PER_PAGE_DATA,
      }

    case MSG_DELETED: {
      let index = -1
      index = state.messages.findIndex(
        msg => msg.id === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          messages: [
            ...state.messages.slice(0, index),
            ...state.messages.slice(index + 1),
          ],
        }
      return state
    }

    default:
      return state
  }
}

export default message_details
