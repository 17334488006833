import * as actionTypes from './actiontypes'
import states from './states'

const brand_functions_details = (state = states.brand_functions_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BRAND_FUNCTIONS_STATE:
      return {
        ...state,
        ...action.payload
      }

    case actionTypes.BRAND_FUNCTION_CREATED:
      return {
        ...state,
        brand_functions: [action.payload.brandFunction, ...state.brand_functions],
        total_bf_count: action.payload.totalCount,
        bf_loading: false
      }

    default:
      return state
  }
}

export default brand_functions_details
