const states = {
  generativefaq_details: {
    loading: false,
    load_error: false,
    faqs: [],
    gf_has_more: false,
    search_key: "",
    trainingOptions: []
  },
}

export default states
