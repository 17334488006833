const states = {
  reports_ui_details: {
    reportsUI: [],
    ru_loading: false,
    ru_load_error: false,
    ru_search_key: "",
    ru_has_more: false,
    ru_header: {
      brand_reports_enabled: false,
      ocs_reports_enabled: false,
    },
  },
}

export default states