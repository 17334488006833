import { getDateRange } from "../../utils/common"
import { MODULE_CONFIG } from "../../config/setup"

const states = {
  tickets_details: {
    is_socket_conneted: false,
    //============= Tickets Home page ================
    tickets: [],
    total_tickets: 0,
    status: "",
    selected_date: getDateRange(15),
    sort_order: null,
    sort_field: null,
    current_page: 1,
    page_size: 10,
    loading: false,
    //============= Ticket View Page ================
    selected_ticket: null,
    messages: [],
    profile: {},
    profile_loading: false,
    page_joining: false,
    //============= Ticket Analytics Page ================
    selected_date_range: getDateRange(10),
    selected_platform: "all",
    stats: [],
    resolved_tickets_data: [],
    rt_granularity: MODULE_CONFIG.GLOBAL.DATE_FILTER.GRANULARITY || "day",
    rt_loading: false,
    rt_load_error: false,
  },
}

export default states
