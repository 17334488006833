import * as actiontypes from './actiontypes'
import states from './states'

const genai_details = (state = states.genai_details, action) => {
  switch (action.type) {
    case actiontypes.UPDATE_GENAI_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default genai_details