import React, { useEffect } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { message } from "antd"

import { APP_PAGES, ROUTE_PATH } from "../../../data/constants/layout"
import { microsoftLogin } from "../../../data/redux/admin_details/actions"

import SpinnerLoader from "../SpinnerLoader"

const MicrosoftAuthentication = ({
  actions,
  history,
  admin_id,
  is_logged_in,
}) => {
  useEffect(() => {
    document.title = APP_PAGES.MICROSOFT_AUTHENTICATION
    if (admin_id && is_logged_in) history.replace(ROUTE_PATH.DASHBOARD)
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has("token")) {
      const payload = {
        token: searchParams.get("token"),
      }
      actions.microsoftLogin(payload, (err, res) => {
        if (!err && res && res.data) history.push(ROUTE_PATH.DASHBOARD)
        else history.push(ROUTE_PATH.LOGIN)
      })
    } else {
      if (searchParams.has("error")) message.error(searchParams.get("error"))
      history.push(ROUTE_PATH.LOGIN)
    }
  }, [history, actions, admin_id, is_logged_in])

  return (
    <div style={{ height: "100vh" }}>
      <SpinnerLoader text="Authenticating" />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    admin_id: state.admin_details.admin_id,
    is_logged_in: state.admin_details.isLoggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        microsoftLogin,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftAuthentication)
