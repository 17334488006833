import { MODULE_CONFIG } from "../../config/setup"
const states = {
  training_logs_details: {
    trainingLogs: [],
    loading: false,
    load_error: false,
    current_page: 1,
    page_size: MODULE_CONFIG.DMS.TRAINING_LOGS_PER_PAGE_TABLE_DATA ,
  },
}

export default states
