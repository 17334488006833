import { getDateRange } from "../../utils/common"

const dateRange = getDateRange()

const states = {
  apilogs_details: {
    apilogs: [],
    has_more: false,
    selected_filters: {
      isSuccess: true,
      statusCode: null,
      sessionId: "",
      startDate: dateRange[0],
      endDate: dateRange[1],
    },
    selected_api: {
      id: "",
      response_data: null,
    },
  },
}

export default states
