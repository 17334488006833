import {UPDATE_GROUP_STATE, GROUP_DELETED} from "./actiontypes"
import states from "./states"

const group_details = (state = states.group_details, action) => {
    switch (action.type) {
        case UPDATE_GROUP_STATE:
            return {
                ...state,
                ...action.payload
            }

        case GROUP_DELETED: {
            let index = -1
            let active_group = { ...state.active_group }
            let groups = { ...state.groups }
            if (action.payload && action.payload._id) {
                index = state.groups.findIndex(response => response._id === action.payload._id)
                if (index !== -1) {
                    groups = [...state.groups.slice(0, index), ...state.groups.slice(index + 1)]
                    if (active_group._id && active_group._id === action.payload._id)
                        active_group = groups.length > 0 ? groups[0] : {}
                }
            }
            return {
                ...state,
                groups,
                active_group
            }
        }

        default:
            return state
    }
}

export default group_details
