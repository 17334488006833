import states from "./states"
import {
  TEST_CASES_UPDATE_STATE
} from "./actiontypes"

const testcases_details = (state = states.testcases_details, action) => {
  switch (action.type) {
    case TEST_CASES_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    
    default:
      return state
  }
}

export default testcases_details
