import states from "./states"
import { MODULE_CONFIG } from "../../config/setup"
import {
  AL_UPDATE_STATE,
  AL_UPDATE_SELECTED_FILTERS,
  AL_LIST_LOADED,
  AL_MORE_ITEM_LOADED
} from "./actiontypes"

const apilogs_details = (state = states.apilogs_details, action) => {
  switch (action.type) {
    case AL_UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      }

    case AL_UPDATE_SELECTED_FILTERS:
      return {
        ...state,
        selected_filters: {
          ...state.selected_filters,
          ...action.payload
        }
      }

    case AL_LIST_LOADED: {
      let index = -1
      if (state.selected_api.id)
        index = action.payload.findIndex(item => item._id === state.selected_api.id)
      return {
        ...state,
        apilogs: action.payload,
        has_more: action.payload.length === MODULE_CONFIG.CONSOLE.APILOGS_LOAD_MORE_COUNT,
        selected_api: index === -1 ? { ...states.apilogs_details.selected_api } : { ...state.selected_api },
      }
    }

    case AL_MORE_ITEM_LOADED:
      return {
        ...state,
        apilogs: [...state.apilogs, ...action.payload],
        has_more: action.payload.length === MODULE_CONFIG.CONSOLE.APILOGS_LOAD_MORE_COUNT
      }

    default:
      return state
  }
}
export default apilogs_details
