import { getDateRange } from "../../utils/common"

const dateRange = getDateRange(1,1)

const states = {
  kpi_details: {
    stats_list: [],
    filters: [],
    selected_filters: {
      startDate: dateRange[0],
      endDate: dateRange[1],
    },
  },
}

export default states
