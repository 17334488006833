import * as actionTypes from "./actiontypes"
import states from "./states"

const downtime_details = (state = states.downtime_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOWNTIME_STATE:
      return {
        ...state,
        ...action.payload
      }

    case actionTypes.DOWNTIME_CREATED: {
      return {
        ...state,
        downtime_list: [action.payload, ...state.downtime_list]
      }
    }

    case actionTypes.DOWNTIME_UPDATED: {
      const index = state.downtime_list.findIndex(downtime => downtime._id === action.payload._id)
      return {
        ...state,
        downtime_list: index !== -1 ? [...state.downtime_list.slice(0, index), action.payload, ...state.downtime_list.slice(index + 1)] : [...state.downtime_list]
      }
    }

    default:
      return state
  }
}

export default downtime_details
