import states from "./states"
import { LCA_UPDATE_STATE } from "./actiontypes"

const chat_analytics_details = (
  state = states.chat_analytics_details,
  action
) => {
  switch (action.type) {
    case LCA_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default chat_analytics_details
