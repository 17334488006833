import states from "./states"
import { BH_UPDATE_STATE, BH_UPDATED } from "./actiontypes"

const broadcast_history_details = (
  state = states.broadcast_history_details,
  action
) => {
  switch (action.type) {
    case BH_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
      case BH_UPDATED: {
        const index = state.bh_list.findIndex(
          item => item._id === action.payload._id
        )
        if (index >= 0) {
          return {
            ...state,
            bh_list: [
              ...state.bh_list.slice(0, index),
              { ...action.payload },
              ...state.bh_list.slice(index + 1),
            ],
          }
        }
        return state
      }  
    default:
      return state
  }
}

export default broadcast_history_details
