import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Marquee from "react-fast-marquee"
import { Alert } from "antd"
import { APP_LAYOUT } from "../../../data/constants/layout"

const AppBanner = ({ alertData }) => {

  if (alertData.message?.length)
    return (
      <Alert
        banner
        style={{ height: APP_LAYOUT.PAGE_BANNER_HEIGHT }}
        type={alertData?.status || "warning"}
        message={
          <Marquee pauseOnHover gradient={false}>
            {alertData.message}
          </Marquee>
        }
      />
    )
  else return null
}

const mapStateToProps = state => ({
  alertData: state.page_details.maintenanceAlertData,
})

AppBanner.propTypes = {
  alertData: PropTypes.object,
}

AppBanner.defaultProps = {
  alertData: {},
}

export default connect(mapStateToProps, null)(AppBanner)
