export const TKT_MAKE_SOCKET_CONNECTION ="TKT_MAKE_SOCKET_CONNECTION"
export const TKT_CALL_SOCKET_METHOD = "TKT_CALL_SOCKET_METHOD"
export const TKT_EMIT_CUSTOM_EVENT = "TKT_EMIT_CUSTOM_EVENT"
export const TKT_JOIN_PAGE = "TKT_JOIN_PAGE"
export const TKT_JOIN_ROOM = "TKT_JOIN_ROOM"

export const TKT_UPDATE_STATE = "TKT_UPDATE_STATE"
export const TKT_SOCKET_DISCONNECT = "TKT_SOCKET_DISCONNECT"
export const TKT_UPDATED = "TKT_UPDATED"
export const TKT_CUSTOMER_PROFILE_RECEIVED = "TKT_CUSTOMER_PROFILE_RECEIVED"
export const TKT_PUSH_MESSAGE = "TKT_PUSH_MESSAGE"
