export const joinChatRoom = "/admin/v1/joinChatRoom"
export const joinTicketChatRoom = "/admin/v2/ticket"
export const getJoinedRoomCustomerProfile = "/admin/v1/getUserProfile"
export const getAgentLiveKpis = "/admin/v1/getLiveKpi"
export const shownJcrContent = "/admin/v1/shownJcrContent"
export const customAgentNoteForm = "/admin/v1/customAgentNotes"
export const getTranslatedText = "/admin/v1/translate"
export const updateAgentSelectedCustomerLang = "/admin/v1/selectCustomerLanguage"
export const startLiveChatSession = "admin/v2/startLiveChatSession"
export const agentsForTicketTransfer = "admin/v2/agentsForTicketTransfer"
export const transferTicket = "admin/v2/transferTicket"