const states = {
    team_member_setting_details: {
        team_members: [],
        team_members_loading: false,
        team_members_load_error: false,
        current_member_permissions: [],
        user_permission_loading: false,
        user_permission_load_error: false
    }
}

export default states
