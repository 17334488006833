export const getChatlogList = "/admin/v1/chatLogs/filtersQuery"
export const getSelectedChatlogDetails = "/admin/v1/chatLogs/chatLogsBySessionId"
export const updateReviewStatus = "/admin/v1/chatLogs/reviewstatus"
export const getChatlogTags = "/admin/v1/botTest/getTestCaseTags"
export const createChatlogTags = "/admin/v1/botTest/createTestCaseTags"
export const createTestcase = "/admin/v1/botTest/create"
export const updateTestcase = "/admin/v1/botTest/updateTestCase"
export const deleteTestcase = "/admin/v1/botTest/deleteTestCase"
export const getTestCaseDetails = "/admin/v1/botTest/fillTestCaseData"
export const updateBlankDetails = "/admin/v2/blank"
export const sendTemplateFromChatHistory = "/admin/v2/sendTemplateFromChatHistory"