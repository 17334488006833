import * as actionTypes from "./actiontypes"
import states from "./states"

const nlp_details = (state = states.nlp_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NLP_STATE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default nlp_details
