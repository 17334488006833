const states = {
  dashboard_details: {
    is_socket_connected: false,
    live_count: [],
    live_traffic_per_sec: [],
    live_traffic_per_min: [],
    stats_loading: false
  },
}

export default states