import states from "./states"
import { TNL_UPDATE_STATE } from "./actiontypes"
const training_logs_details = (state = states.training_logs_details, action) => {
  switch (action.type) {
    case TNL_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default training_logs_details