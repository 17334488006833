import { MODULE_CONFIG } from "../../config/setup"

const states = {
  model_accuracy_details: {
    //ma->model accuracy
    ma_load_error: false,
    ma_loading: false,
    ma_intents: [],
    ma_has_more: false,
    ma_options: [],
    ma_selected_sources: [],
    ma_search_key: "",

    // mau-> model accuracy utterances
    mau_utterances: [],
    mau_loading: false,
    mau_load_error: false,
    mau_current_page: 1,
    mau_page_size: MODULE_CONFIG.ANALYTICS.MA_UTTERANCES_PER_PAGE_DATA,
    mau_total_count: 0,
  },
}
export default states
