import { getDateRange } from "../../utils/common"

const states = {
  genai_details: {
    filter_date_range: getDateRange(4),
    graphs: [],
    metrics: [],
    loading: false,
    load_error: false,
    date_range_in_days: 30,
    gl_loading: false,
    gl_load_error: false,
    gl_total: 0,
    gl_current_page: 1,
    gl_page_size: 10,
    gl_columns: [],
    gl_initial_load: true,
    gl_session_filter: "",
    gl_passcase_filter: "",
    gl_flags_filter: [],
    gl_list: [],
    gl_flags_list: [],
    gl_sort_by_value: "latest",
    gl_pass_case: "all",
    gl_sorters: []
  },
}

export default states