const states = {
  dialogue_details: {
    //============================= DIALOGUES =============================
    page_size: 10,
    current_page: 1,
    sort_order: null,
    sort_field: null,
    status: true,
    search_key: "",
    search_type: "intent",
    total_records: 0,
    loading: false,
    load_error: false,
    records: [], //TODO: rename with dialogues
    //============================= EDIT DIALOGUE  =============================
    is_socket_connected: false,
    dialogues_training: false,
    joining_dialogue: false,
    dialogue_name: "",
    messages: [],
    is_sync: false,
    match_dialogues: [],
    summary: [],
    input_lock: false,
    selected_msg_id: null,
    selected_msg_user_text: "",
    //------ User Utterances > us ------
    user_utterances: [],
    //------ Predicted Entities > pe ------
    predicted_entities: {},
    //------ Predicted Intents => pi ------
    predicted_intents: [],
    pi_total: 0,
    pi_page_size: 5,
    pi_current_page: 1,
    pi_search_key: "",
    //------ Predicted Actions => pa ------
    predicted_actions: [],
    pa_total: 0,
    pa_page_size: 5,
    pa_current_page: 1,
    pa_search_key: "",
  },
}

export default states
