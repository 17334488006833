import { getDateRange } from "../../utils/common"

const states = {
  // bsm=bot success metrics
  botsuccess_metrics_details: {
    bsm_selected_platform: "all",
    bsm_selected_date_range: getDateRange(3),

    //================= Stats ===================

    bsm_stats: [],

    //================= session metric (sm)===================
    sm_granularity: "day",
    sm_loading: false,
    sm_load_error: false,
    sessions: [],
    sm_scale: {
      session: {
        min: 0,
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },

    //==============Intent recorgnition and fallback===========
    irrf_granularity: "day",
    irrf_loading: false,
    irrf_load_error: false,
    intentRecorgnitionAndFallback: [],
    irrf_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },

    //============== cost saved ==================
    costSavedAI: [],
    cs_load_error: false,
    cs_loading: false,
    cs_scale: {
      cost: {
        min: 0,
        formatter(value) {
          return `$${value}`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    cs_granularity: "day",

    //=================== customerSatisfactionScore ================
    customerSatisfactionScore: [],
    css_load_error: false,
    css_loading: false,
    css_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    css_granularity: "day",

    //=================Bounce rate================

    bounceRate: [],
    br_load_error: false,
    br_loading: false,
    br_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    br_granularity: "day",

    //============ flow completion rate============
    flowCompletionRate: [],
    fcr_load_error: false,
    fcr_loading: false,
    fcr_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    fcr_granularity: "day",

    //=============== self service rate ===============

    selfServiceRate: [],
    ssr_load_error: false,
    ssr_loading: false,
    ssr_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    ssr_granularity: "day",

    //============== live agent transfer==========

    liveAgentTransfer: [],
    lat_load_error: false,
    lat_loading: false,
    lat_scale: {
      percent: {
        min: 0,
        formatter(value) {
          return `${value}%`
        },
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    lat_granularity: "day",
  },
}
export default states
