const states = {
    group_details: {
      groups: [],
      active_group: {},
      groups_loading: false,
      groups_load_error: false
    }
  }
  
  export default states
  