import {
    UPDATE_EV_STATE,
} from './actiontypes'
import states from './states'

const environment_variables_details = (state = states.environment_variables_details, action) => {
    switch (action.type) {
        case UPDATE_EV_STATE:
            return {
                ...state,
                ...action.payload
            }

        default:
            return state
    }
}

export default environment_variables_details
