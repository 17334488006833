import React from "react"
import dayjs from "dayjs"

import { UPDATE_USAGE_STATE } from "./actiontypes"
import * as API from "./api"
import { fetchDataAndProceed } from "../../utils/dataUtility"

import TableItemProgress from "../../../App/components/TableItemProgress"

export const updateUsageState = payload => {
  return {
    type: UPDATE_USAGE_STATE,
    payload,
  }
}

export const getChatSessionTimeSeries = (data, callback) => dispatch => {
  dispatch(
    updateUsageState({
      chat_session_loading: true,
      chat_session_load_error: false,
    })
  )
  fetchDataAndProceed(
    {
      url: API.getChatSessionTimeSeries,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response && response.chatSessionsTimeSeries) {
        const payload = {
          chat_session_loading: false,
          chat_session_load_error: false,
          chat_session_time_series: response.chatSessionsTimeSeries,
        }
        dispatch(updateUsageState(payload))
        if (callback) callback(response.chatSessionsTimeSeries)
      } else
        dispatch(
          updateUsageState({
            chat_session_loading: false,
            chat_session_load_error: true,
          })
        )
    }
  )
}

export const getUserEngagementStats = data => dispatch => {
  dispatch(
    updateUsageState({ user_stats_loading: true, user_stats_load_error: false })
  )
  fetchDataAndProceed(
    {
      url: API.getUserEngagementStats,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response.metrics) {
        const payload = {
          user_stats_loading: false,
          user_stats_load_error: false,
          user_engagement_stats: response.metrics,
        }
        dispatch(updateUsageState(payload))
      } else
        dispatch(
          updateUsageState({
            user_stats_loading: false,
            user_stats_load_error: true,
          })
        )
    }
  )
}

export const getUserRetention = (data, callback) => dispatch => {
  dispatch(
    updateUsageState({
      user_retention_loading: true,
      user_retention_load_error: false,
    })
  )
  fetchDataAndProceed(
    {
      url: API.getUserRetention,
      data,
      loader: false,
    },
    (error, response) => {
      if (
        !error &&
        response &&
        response.cohortsColumns &&
        response.cohortsData
      ) {
        response.cohortsColumns.forEach(col => {
          if (col.type && col.type === "retention_value") {
            col.render = value => <TableItemProgress value={value} />
          }
          if (col.key && col.key === "New Users") {
            col.render = text => (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {text}
              </p>
            )
            if (response.cohortsColumns.length > 21) col.fixed = true
          }
          if (col.key === "Date" && response.cohortsColumns.length > 21)
            col.fixed = true
        })
        response.cohortsData.forEach(data => {
          if (data.Date) data.Date = dayjs(data.Date).format("DD MMM YYYY")
        })
        const payload = {
          user_retention_loading: false,
          user_retention_load_error: false,
          user_retention_cohorts_columns: response.cohortsColumns,
          user_retention_cohorts_data: response.cohortsData,
        }
        dispatch(updateUsageState(payload))
        if (callback) callback()
      } else
        dispatch(
          updateUsageState({
            user_retention_loading: false,
            user_retention_load_error: true,
          })
        )
    }
  )
}
