// import { variable } from "../botparams_details/api"

// const variables = [
//     {
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },{
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },{
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },{
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },{
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },{
//         "_id": "621e087ba8d1a6dd3df1a08a",
//         "name": "abhi 4",
//         "production": true,
//         "development": true,
//         "envValues": {
//             "production": "PROD.",
//             "development": "DEV",
//             "_id": "621e087ba8d1a6dd3df1a08b"
//         },
//         "isDeleted": null,
//         "upDatedAt": null,
//         "createdAt": "2022-01-03T11:50:19.000Z",
//         "deletedAt": null,
//         "__v": 0
//     },
// ]

const states = {
  environment_variables_details: {
    env_records: [],
    env_search_key: "",
    env_loading: false,
    env_load_error: false,
    env_load_more: false,
    env_current_page: 1,
    env_page_size: 10,
    env_sort_order: null,
    env_sort_field: null,
    env_total_records: 0,
    env_initial_load: true,
  },
}

export default states
