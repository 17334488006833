export const UPDATE_SESSION_FLOW_STATE = "UPDATE_SESSION_FLOW_STATE"
export const SET_STARTOVER = "SET_STARTOVER"
export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA"
export const UPDATE_DEFAULT_FILTERS = "UPDATE_DEFAULT_FILTERS"
export const VIEW_CREATED = "VIEW_CREATED"
export const VIEW_DELETED = "VIEW_DELETED"
export const VIEW_UPDATED = "VIEW_UPDATED"
export const GROUP_CREATED = "GROUP_CREATED"
export const GROUP_UPDATED = "GROUP_UPDATED"
export const GROUP_DELETED = "GROUP_DELETED"
