const states = {
  template_cta_details: {
    //tc => template cta
    templates: [],
    tc_has_more: false,
    tc_search_key: "",
    tc_loading: false,
    tc_load_error: false,
  },
}

export default states
