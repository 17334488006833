import { message } from "antd"

import * as API from "./api"
import { fetchDataAndProceed } from "../../utils/dataUtility"
import { log, updateProfileSectionData } from "../../utils/common"
import { METHOD_TYPES } from "../../constants/common"
import {
  TKT_UPDATE_STATE,
  TKT_UPDATED,
  TKT_JOIN_PAGE,
  TKT_MAKE_SOCKET_CONNECTION,
  TKT_CALL_SOCKET_METHOD,
  TKT_SOCKET_DISCONNECT,
  TKT_EMIT_CUSTOM_EVENT,
  TKT_CUSTOMER_PROFILE_RECEIVED,
  TKT_JOIN_ROOM,
} from "./actiontypes"

export const updateTicketsState = payload => ({
  type: TKT_UPDATE_STATE,
  payload,
})

export const getTicketsList = data => dispatch => {
  dispatch(
    updateTicketsState({
      loading: true,
    })
  )
  fetchDataAndProceed(
    {
      url: API.getTicketsList,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (err, res) => {
      log("getTicketsList response", res)
      if (!err && res && res.data && res.data.tickets) {
        dispatch(
          updateTicketsState({
            tickets: res.data.tickets,
            total_tickets: res.data.totalCount || 0,
            loading: false,
          })
        )
      } else {
        dispatch(
          updateTicketsState({
            tickets: [],
            loading: false,
          })
        )
      }
    }
  )
}

export const assignTicket = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.assignTicket,
      method: METHOD_TYPES.POST,
      data,
      loader_text: "Assigning",
    },
    (err, res) => {
      log("assignTicket response", res)
      if (!err && res && res?.data?.updatedTicket?.ticketId) {
        dispatch({
          type: TKT_UPDATED,
          payload: res.data.updatedTicket,
        })
        message.success(res.message ? res.message : "Ticket has been assigned")
        if (callback) callback()
      }
    }
  )
}

//================================= TICKET SOCKET ACTIONS ====================================

export const resolveTicket = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.resolveTicket,
      method: METHOD_TYPES.POST,
      data,
      loader_text: "Resolving",
    },
    (err, res) => {
      log("resolveTicket response", res)
      if (!err && res && res?.data?.updatedTicket?.ticketId) {
        dispatch({
          type: TKT_UPDATED,
          payload: res.data.updatedTicket,
        })
        message.success(
          res.message ? res.message : "Ticket updated successfully"
        )
        if (callback) callback()
      }
    }
  )
}

export const makeTicketSocketConnection = host => ({
  type: TKT_MAKE_SOCKET_CONNECTION,
  host,
})

export const callTicketSocketMethod = payload => ({
  type: TKT_CALL_SOCKET_METHOD,
  payload,
})

export const ticketSocketDisconnect = () => ({
  type: TKT_SOCKET_DISCONNECT,
})

export const emitTicketCustomEvent = (event, payload, callback) => ({
  type: TKT_EMIT_CUSTOM_EVENT,
  payload,
  event,
  callback,
})

export const joinTicketPage = (payload = {}) => ({
  type: TKT_JOIN_PAGE,
  payload,
})

export const joinTicketRoom = payload => ({
  type: TKT_JOIN_ROOM,
  payload,
})

export const getCustomerProfile = data => dispatch => {
  dispatch(updateTicketsState({ profile_loading: true }))
  fetchDataAndProceed(
    {
      url: API.getCustomerProfile,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (err, res) => {
      dispatch(updateTicketsState({ profile_loading: false }))
      if (
        !err &&
        res &&
        res?.data?.changedUserProfileDetails?.userProfileDetails &&
        res?.data?.ticketId
      ) {
        const profile = {
          ...res.data.changedUserProfileDetails.userProfileDetails,
        }
        if (profile.sections && profile.sections.length > 0)
          profile.sections = updateProfileSectionData(profile.sections)
        dispatch({
          type: TKT_CUSTOMER_PROFILE_RECEIVED,
          payload: {
            profile,
            ticketId: res.data.ticketId,
          },
        })
      }
    }
  )
}

//================================= TICKET ANALYTICS ACTIONS ====================================

export const getTicketStats = data => dispatch => {
  fetchDataAndProceed(
    {
      url: API.getTicketStats,
      data,
    },
    (err, res) => {
      if (!err && res.data.stats)
        dispatch(updateTicketsState({ stats: res.data.stats }))
      else
        dispatch(
          updateTicketsState({
            stats: [],
          })
        )
    }
  )
}

export const getResolvedTicketsData = data => dispatch => {
  dispatch(
    updateTicketsState({
      rt_loading: true,
      rt_load_error: false,
    })
  )
  fetchDataAndProceed(
    {
      url: API.getResolvedTicketsData,
      data,
      loader: false,
    },
    (err, res) => {
      if (!err && res && res.data.ticketChartData) {
        dispatch(
          updateTicketsState({
            rt_loading: false,
            rt_load_error: false,
            resolved_tickets_data: res.data.ticketChartData,
          })
        )
      } else
        dispatch(
          updateTicketsState({
            rt_loading: false,
            rt_load_error: true,
          })
        )
    }
  )
}
