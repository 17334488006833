import dayjs from "dayjs"

const states = {
  conversion_details: {
    filters: {
      startDate: dayjs().subtract(7, "day"),
      endDate: dayjs(),
    },
    funnel_data: [],
    top_conversion_drivers: [],
    funnel_data_loading: false,
    funnel_data_load_error: false,
  },
}

export default states
