import {io} from "socket.io-client"
import { message as displayMessage } from "antd"
import dayjs from "dayjs"

import * as API from "./api"
// import { updatePageState } from "../page_details/actions"
import {
  updateTicketsState,
  joinTicketPage,
  joinTicketRoom,
  getCustomerProfile,
} from "./actions"
import {
  TKT_MAKE_SOCKET_CONNECTION,
  TKT_CALL_SOCKET_METHOD,
  TKT_EMIT_CUSTOM_EVENT,
  TKT_SOCKET_DISCONNECT,
  TKT_JOIN_PAGE,
  TKT_JOIN_ROOM,
} from "./actiontypes"

import { getAuthSocketData, fetchDataAndProceed } from "../../utils/dataUtility"
import { SOCKET_EVENTS } from "../../constants/socket"
import { METHOD_TYPES } from "../../constants/common"
import { ROUTE_PATH } from "../../constants/layout"
import { sendPushNotification } from "../../utils/pushNotification"
import { log } from "../../utils/common"

import { IMG_NEW_MESSAGE } from "../../assets"

const registerSocketListener = (store, socket) => {
  const adminPsid = store.getState().admin_details.admin_id

  socket.on(SOCKET_EVENTS.CONNECT, () => {
    store.dispatch(
      updateTicketsState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
    store.dispatch(
      updateTicketsState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.ERROR, () => {
    store.dispatch(
      updateTicketsState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.DISCONNECT, reason => {
    if (reason === "io server disconnect") socket.connect()
    store.dispatch(
      updateTicketsState({ is_socket_connected: socket.connected })
    )
  })

  socket.on(SOCKET_EVENTS.RESPONSE, data => {
    log(SOCKET_EVENTS.RESPONSE, data)
    if (
      data &&
      data.ticketId &&
      data.result &&
      data.result.bot_messages &&
      data.result.bot_messages.length > 0
    ) {
      const message = data.result.bot_messages[0]
      if (data.chatlogId) message.chatlogId = data.chatlogId
      const tickets_details = store.getState().tickets_details
      if (tickets_details.selected_ticket?.ticketId === data.ticketId)
        store.dispatch(
          updateTicketsState({
            messages: [...tickets_details.messages, message],
          })
        )
    }
  })

  socket.on(SOCKET_EVENTS.USER_MESSAGE, data => {
    log(SOCKET_EVENTS.USER_MESSAGE, data)
    if (data && data.ticketId && data.message) {
      const message = {
        ...data.message,
        NLPSnapshot: data.NLPSnapshot ? data.NLPSnapshot : null,
        prevNLPSnapshot: data.prevNLPSnapshot ? data.prevNLPSnapshot : null,
      }
      const tickets_details = store.getState().tickets_details
      if (tickets_details.selected_ticket?.ticketId === data.ticketId) {
        store.dispatch(
          updateTicketsState({
            messages: [...tickets_details.messages, message],
          })
        )

        if (
          tickets_details.selected_ticket?.adminPsid === adminPsid &&
          (document.visibilityState === "hidden" ||
            window.location.pathname !== ROUTE_PATH.TICKETS ||
            !document.hasFocus())
        ) {
          const options = {
            body: `New message received ${dayjs().format("hh:mm A")}`,
            icon: IMG_NEW_MESSAGE,
          }
          sendPushNotification(options)
        }
      }
    }
  })
}

const middleware = () => {
  let socket = null
  return store => next => action => {
    switch (action.type) {
      case TKT_MAKE_SOCKET_CONNECTION: {
        if (socket !== null) socket.close()
        const AUTH_SOCKET_DATA = getAuthSocketData()
        fetch("https://api64.ipify.org?format=json")
          .then(response => response.json())
          .then(data => {
            AUTH_SOCKET_DATA.query.publicIP = data.ip
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
          .catch(() => {
            socket = io(action.host, AUTH_SOCKET_DATA)
            registerSocketListener(store, socket)
          })
        break
      }

      case TKT_CALL_SOCKET_METHOD:
        if (socket) socket[action.payload]()
        break

      case TKT_EMIT_CUSTOM_EVENT:
        if (socket && action.event)
          socket.emit(action.event, action.payload, (err, res) => {
            log(TKT_EMIT_CUSTOM_EVENT, action.event, res)
            if (action.callback) action.callback(err, res)
            if (err && res && res.errorCode && res.errorCode === 204) {
              displayMessage.error(
                res && res.message
                  ? res.message
                  : `${action.event} error occured`
              )
              store.dispatch(joinTicketPage())
            }
          })
        break

      case TKT_JOIN_PAGE:
        if (socket) {
          store.dispatch(updateTicketsState({ page_joining: true }))
          socket.emit(SOCKET_EVENTS.JOIN_TICKET, action.payload, (err, res) => {
            if (
              !err &&
              res &&
              res.data &&
              res.data?.liveTicketData?.ticketInfo
            ) {
              store.dispatch(
                updateTicketsState({
                  page_joining: false,
                  selected_ticket: res.data.liveTicketData.ticketInfo,
                })
              )
              store.dispatch(
                joinTicketRoom({
                  ticketId: res.data.liveTicketData.ticketInfo.ticketId,
                  clientPsid: res.data.liveTicketData.ticketInfo.psid,
                  adminPsid: action.payload.adminPsid,
                })
              )
            } else {
              store.dispatch(updateTicketsState({ page_joining: false }))
              displayMessage.error(
                res && res.message
                  ? res.message
                  : "joinTicket event error occured"
              )
            }
          })
        }
        break

      case TKT_JOIN_ROOM:
        if (socket) {
          fetchDataAndProceed(
            {
              url: API.joinTicketRoom,
              method: METHOD_TYPES.POST,
              data: {
                socketId: socket.id,
                timestamp: new Date().getTime(),
                ticketId: action.payload.ticketId,
                psid: action.payload.clientPsid,
                adminPsid: action.payload.adminPsid,
              },
              loader_text: "Joining chatroom",
            },
            (err, res) => {
              if (
                !err &&
                res &&
                res.data?.activeChatSessionLogs &&
                res.data?.ticketId
              ) {
                const selectedTicket =
                  store.getState().tickets_details.selected_ticket
                if (
                  selectedTicket?.ticketId &&
                  selectedTicket.ticketId === res.data.ticketId
                ) {
                  store.dispatch(
                    updateTicketsState({
                      messages: res.data.activeChatSessionLogs,
                    })
                  )
                  store.dispatch(
                    getCustomerProfile({
                      clientPsid: action.payload.clientPsid,
                      ticketId: action.payload.ticketId,
                    })
                  )
                }
              }
            }
          )
        }
        break

      case TKT_SOCKET_DISCONNECT:
        if (socket !== null) socket.close()
        socket = null
        break

      default:
        return next(action)
    }
  }
}

export default middleware()
