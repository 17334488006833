const states = {
  faqbuilder_details: {
    search_key: "",
    faq_loading: false,
    faq_load_error: false,
    addNewFaq: null,
    selectedFaqs: [],
    selectAll: false,
    hasMore: false,
    global_settings: {},
    faqs: [],
  },
}
export default states