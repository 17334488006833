import * as actionTypes from "./actiontypes"
import states from "./states"

const agent_details = (state = states.agent_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AGENT_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case actionTypes.UPDATE_ACTIVE_AGENT: {
      const active_agent = action.payload.agentList.find(
        agent => agent.agentId === action.payload.agentId
      )
      if (active_agent) {
        return {
          ...state,
          active_agent,
        }
      } else {
        return state
      }
    }

    case actionTypes.AGENT_DELETED: {
      let index = -1
      index = state.agents.findIndex(
        agent => agent._id === action.payload[0]._id
      )
      const isSelectedIdDeleted= state.active_agent?._id===action.payload[0]?._id
      if (index !== -1)
        return {
          ...state,
          agents: [
            ...state.agents.slice(0, index),
            ...state.agents.slice(index + 1),
          ],
          active_agent: isSelectedIdDeleted ? {} : state.active_agent
        }
      return state
    }

    case actionTypes.NEW_AGENTS_ADDED:
      return {
        ...state,
        agents: [...action.payload, ...state.agents],
      }

    default:
      return state
  }
}

export default agent_details
