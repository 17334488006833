import * as actionTypes from './actiontypes'
import states from './states'

const usage_details = (state = states.usage_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USAGE_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default usage_details