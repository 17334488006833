const states = {
  // bh=broardcast_history
  broadcast_history_details: {
    bh_loading: false,
    bh_load_error: false,
    bh_list: [],
    bh_current_page: 1,
    bh_page_size: 10,
    bh_total: 0,
    bh_initial_load: true,
    selected_date_range: [],
    bh_filters:{}
  },
}
export default states
