import {
  UPDATE_DASHBOARD_STATE,
  DASHBOARD_MAKE_SOCKET_CONNECTION,
  DASHBOARD_SOCKET_DISCONNECT,
  EMIT_DASHBOARD_CUSTOM_EVENT,
  CALL_DASHBOARD_SOCKET_METHOD
} from './actiontypes'

export const updateDashboardState = payload => {
  return {
    type: UPDATE_DASHBOARD_STATE,
    payload
  }
}

export const makeDashboardSocketConnection = host => {
  return {
    type: DASHBOARD_MAKE_SOCKET_CONNECTION,
    host
  }
}

export const callDashboardSocketMethod = payload => {
  return {
    type: CALL_DASHBOARD_SOCKET_METHOD,
    payload
  }
}

export const dashboardSocketDisconnect = () => {
  return {
    type: DASHBOARD_SOCKET_DISCONNECT,
  }
}

export const emitDashboardCustomEvent = (event, payload, callback) => {
  return {
    type: EMIT_DASHBOARD_CUSTOM_EVENT,
    event,
    payload,
    callback
  }
}
