export const BRAND_INFO = {
  BRAND_NAME: process.env.REACT_APP_BRAND_NAME,
  NOTIFICATION_TITLE: process.env.REACT_APP_BRAND_NOTIFICATION_TITLE,
  usage_home_page: false, // by default it should be false
  data: {
    socket_query: {
      brandName: process.env.REACT_APP_BRAND_NAME,
      channelName: "web",
      role: "admin",
    },
  },
  BRAND_LOGO: process.env.REACT_APP_LOGO_BRAND_NAME,
  LOGIN_TITLE: process.env.REACT_APP_LOGIN_TITLE,
  BRAND_AVATAR: process.env.REACT_APP_BRAND_LOGO,
}

export const MODULE_CONFIG = {
  GLOBAL: {
    ALLOWED_DATE_RANGE: 30,
    DATE_FILTER: {
      START_DATE_DIFFERENCE: process.env.REACT_APP_START_DATE_DIFFERENCE,
      END_DATE_DIFFERENCE: process.env.REACT_APP_END_DATE_DIFFERENCE,
      GRANULARITY: process.env.REACT_APP_DATE_FILTER_GRANUALITY
    },
    AUTO_LOGOUT: {
      SHOW_POPUP_TIME: process.env.REACT_APP_SHOW_POPUP_TIME || 45 * 60000, // 45 min
      AUTO_LOGOUT_TIME: process.env.REACT_APP_AUTO_LOGOUT_TIME || 2 * 60000, // 2 min
    },
    API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT || 20000, // 20 seconds
    INTENT_THRESHOLD_SCORE: 0.5,
    AGENT_LIVE_KPI_POLLING_INTERVAL: 10 * 60000, // 10 min (minimun 2 min bz it is using ajax request)
    DOWNLOAD_CHATLOGS_PDF: {
      X_MARGIN: 25,
      Y_MARGIN: 25,
    },
    HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT: window.innerWidth < 786 ? 15 : 30,
    MOBILE_WINDOW_OPTIONS: [
      { label: "Chat Window", value: "window" },
      { label: "Client Profile", value: "profile" },
    ],
    WHATSAPP_FILE_UPLOAD_ALLOWED_TYPES: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    NLP_FILE_UPLOAD_ALLOWED_TYPES: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
    UTTERANCE_LIST_PER_PAGE_DATA: 20,
    WORKFLOW_TREE__FILE_UPLOAD_ALLOWED_TYPES: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ],
    COMMON_FILE_UPLOAD_ALLOWED_TYPES: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
  },
  ANALYTICS: {
    QA_INTENT_RECORDS_PER_PAGE_DATA: 10,
    AGENT_MONITORING: {
      LIVE_GROUPS_POLLING_TIME_INTERVAL: 5000,
      LIVE_AGENTS_POLLING_TIME_INTERVAL: 10000,
      SELECTED_AGENT_LIVE_CHATS_POLLING_TIME_INTERVAL: 5000,
      WORK_SHEET_OPTIONS: [
        "Chat Report Raw Dump",
        "Chat Message Report Summary",
        "Total Chats Report",
      ],
    },
    CHATLOGS_ANALYTICS: {
      GRANULARITY_OPTIONS: [
        { label: "Hour", value: "hour" },
        { label: "Day", value: "day" },
        { label: "Week", value: "week" },
        { label: "Month", value: "month" },
      ],
      TRAINING_MSGS_PER_PAGE_TABLE_DATA: 10,
    },
    MA_UTTERANCES_PER_PAGE_DATA: 10,
    MA_INTENTS_PER_PAGE_DATA: 30,
  },
  BOT_BUILDER: {
    WF_ALLOWED_IMAGE_TYPE: ["image/png", "image/jpeg"],
    WF_ALLOWED_VIDEO_TYPE: ["video/mp4"],
    WF_ALLOWED_VIDEO_SIZE: 5 * 1024 * 1024,
    WF_ALLOWED_DOCUMENT_SIZE: 5 * 1024 * 1024,
    WF_ALLOWED_DOCUMENT_TYPE: ["*"],
    WF_RECORDS_PER_PAGE_DATA: 20,
    WF_JSON_REQUEST_METHOD_TYPES: [
      { label: "POST", value: "post" },
      { label: "GET", value: "get" },
    ],
    WF_KEY_TYPES_IN_CONDITIONAL_NODE: [
      { label: "Attribute", value: "attribute" },
      // { label: "Intent", value: "intent" },
      // { label: "Entity", value: "entity" },
    ],
    COND_TYPE_IN_CONDITIONAL_NODE: [
      { label: "equal to", value: "equal to" },
      { label: "not equal to", value: "not equal to" },
      { label: "greater than", value: "greater than" },
      { label: "greater than or equal to", value: "greater than or equal to" },
      { label: "less than", value: "less than" },
      { label: "less than or equal to", value: "less than or equal to" },
      { label: "exists", value: "exists" },
      { label: "in list", value: "in list" },
      { label: "not in list", value: "not in list" },
      { label: "starts with", value: "starts with" },
      { label: "does not starts with", value: "does not starts with" },
      { label: "ends with", value: "ends with" },
      { label: "does not ends with", value: "does not ends with" },
      { label: "contains", value: "contains" },
      { label: "does not contain", value: "does not contain" },
    ],
    DEFAULT_CONDITION_VALUE: {
      rootConditionType: "and",
      conditionSet: [
        {
          keyType: "attribute",
          keyValue: "",
          condType: "equal to",
          value: "",
        },
      ],
    },
    ELSE_CONDITION_VALUE: {
      rootConditionType: "",
      conditionSet: [],
      editable: false,
      deletable: false,
      isElseCondition: true,
    },
    ENVIRONMENT_VARIABLES: {
      EV_PER_PAGE_TABLE_DATA: 10,
    },
    CAT_RECORDS_PER_PAGE_DATA: 20, //CAT=> Catalogue
    MSG_RECORDS_PER_PAGE_DATA: 20, //MSG=> Messagages
    FAQ_RECORDS_PER_PAGE_DATA: 10, //FAQ=> FAQBuilder Page
    BOT_PARAMS_PER_PAGE_DATA: 10,
    GENERATIVE_FAQ_RECORDS_PER_PAGE_DATA: 10, //FAQ=> GenerativeFAQ Page
  },
  CHATS: {
    AUTO_JOIN_ON_ACCEPT_CHAT: true,
    STORED_LIVE_CHAT_ITEMS_COUNT: 4,
    LONG_CHAT_HANDLING_TIME: 120000, // in miliseconds
    CRITICAL_CHAT_HANDLING_TIME: 300000, // in miliseconds
    EDITOR: {
      STRIP_PASTED_STYLES: true,
      FORMATS: [
        "bold",
        "italic",
        "underline",
        "strike",
        "color",
        "background",
        "link",
        // "code-block",
        "list",
        // "image",
      ],
    },
    transferChatToSupervisor: record => {
      // record.online !== "true" ||
      // record.state === "1st break" ||
      // record.state === "2nd break" ||
      // record.state === "out for lunch" ||
      // record.state === "out for dinner"
      return false
    },
  },
  HISTORY: {
    LOAD_MORE_CHATLOGS_COUNT: 15,
  },
  TICKET_HISTORY: {
    LOAD_MORE_TICKETLOGS_COUNT: 15,
  },
  MEMBERS: {
    MAX_INVITE_AGENT_COUNT_USING_CSV: process.env
      .REACT_APP_MAX_INVITE_AGENT_COUNT_USING_CSV
      ? parseInt(process.env.REACT_APP_MAX_INVITE_AGENT_COUNT_USING_CSV, 10)
      : 20,
    SAMPLE_CSV_URL: process.env.REACT_APP_INVITE_AGENT_SAMPLE
      ? process.env.REACT_APP_INVITE_AGENT_SAMPLE
      : "https://s3.ap-south-1.amazonaws.com/oriserve.com/features/InviteAgentSample.csv",
    CANNED_RESPONSE_SAMPLE_XLSX_URL:
      "https://s3.ap-south-1.amazonaws.com/oriserve.com/features/Canned_Resposnes_Bulk.xlsx",
    TAGS_SAMPLE_XLSX_URL:
      "https://s3.ap-south-1.amazonaws.com/oriserve.com/features/Tags_Bulk.xlsx",
  },
  CONSOLE: {
    REPORTS_PER_PAGE_TABLE_DATA: 10,
    APILOGS_LOAD_MORE_COUNT: 10,
    REPORTS_CONFIGURATION_PER_PAGE_TABLE_DATA: 20,
    REPORT_UI_PER_PAGE_DATA: 10,
  },
  CUSTOMERS: {
    CUSTOMERS_PER_PAGE_TABLE_DATA: 10,
    LOAD_MORE_CUSTOMER_CHATLOG_COUNT: 10,
  },
  INTENTS: {
    LIST_UTTERANCES_PER_PAGE: 10,
    UTTERENCE_SAMPLE_XLSX_URL:
      "https://s3.ap-south-1.amazonaws.com/oriserve.com/features/Utterances_Bulk.xlsx",
  },
  QUERY_ANALYTICS: {
    LOAD_MORE_CLUSTER_REQUESTS: 10,
  },
  DMS: {
    TRAINING_LOGS_PER_PAGE_TABLE_DATA: 5,
  },
  MODEL_MANAGEMENT: {
    MODELS_PER_PAGE_TABLE_DATA: 10,
    LOAD_MORE_DEPLOYMENT_LOGS: 10,
  },
  WHATSAPP_TEMPLATE: {
    SAMPLE_XLSX_URL:
      "https://s3.ap-south-1.amazonaws.com/oriserve.com/whatsapp_templates_csv/sample_template.xlsx",
    TEMPLATE_CTA_RECORDS_PER_PAGE_DATA: 20,
  },
}

export const MODULE_MESSAGE = {
  DASHBOARD: {},
  ANALYTICS: {},
  MEMBERS: {
    AGENTS: {
      invite_using_csv:
        "select a csv file same as sample.csv. we will read data from csv file and allow you to invite them. i suggest you to verify all agents data before click on invite button",
      invite_manually:
        "click on the below button and manually enter each agent required information and invite them.",
    },
  },
  SETTINGS: {
    TEAM_MEMBER_SETTING: {
      add_member_status:
        "Your team is the face of your business, engaging and conversing with your users. You can see the full list of team members, add new members, and edit member details from this page.",
      MEMBER_PERMISSIONS: {
        not_access:
          "You don't have the privileges to change this member's permission with these credentials. Contact your administrator.",
        self_account:
          "You can not change your permissions yourself. Contact your administrator to change your permissions.",
      },
    },
  },
}
