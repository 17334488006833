const states = {
  roles_details: {
    roles: [],
    loading: false,
    loadError: false,
    selectedRole: {type: "custom"},
    member: [],
    synced: true,
    defaultSelectedRoleId: "",
    roleDescription: ""
  },
}

export default states
