import {
  UPDATE_ROLES_STATE,
  PERMISSION_ADDED,
  R_Member,
} from "./actiontypes"
import states from "./states"

const roles_details = (state = states.roles_details, action) => {
  switch (action.type) {
    case UPDATE_ROLES_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case PERMISSION_ADDED:
      return {
        ...state,
        roleDescription: action?.payload?.description,
        selectedRole: {
          ...state.selectedRole,
          permissions: action?.payload?.permissions,
          parentRoleId: action?.id || "",
        },
      }

    case R_Member: {
      const filterMember = action.payload.filter(
        agent => agent.role === state.selectedRole.name
      )
      return {
        ...state,
        member: filterMember,
      }
    }

    default:
      return state
  }
}

export default roles_details
