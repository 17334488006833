import {
  UPDATE_REPORTS_UI_STATE,
  RU_LOAD_MORE,
  RU_CREATED,
  RU_UPDATED,
  RU_DELETED,
} from "./actiontypes"

import { MODULE_CONFIG } from "../../config/setup"
import states from "./states"

const reports_ui_details = (state = states.reports_ui_details, action) => {
  switch (action.type) {
    case UPDATE_REPORTS_UI_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case RU_LOAD_MORE:
      return {
        ...state,
        reportsUI: [...state.reportsUI, ...action.payload],
        ru_has_more:
          action.payload.length ===
          MODULE_CONFIG.CONSOLE.REPORT_UI_PER_PAGE_DATA,
      }
    case RU_CREATED:
      return {
        ...state,
        reportsUI: [action.payload, ...state.reportsUI],
      }

    case RU_DELETED: {
      let index = -1
      index = state.reportsUI.findIndex(
        reportsUI => reportsUI.id === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          reportsUI: [
            ...state.reportsUI.slice(0, index),
            ...state.reportsUI.slice(index + 1),
          ],
        }
      return state
    }

    case RU_UPDATED: {
      let index = -1
      index = state.reportsUI.findIndex(reportsUI => action.id === reportsUI.id)
      if (index !== -1)
        return {
          ...state,
          reportsUI: [
            ...state.reportsUI.slice(0, index),
            {
              ...state.reportsUI[index],
              ...action.payload,
            },
            ...state.reportsUI.slice(index + 1),
          ],
        }
      return state
    }

    default:
      return state
  }
}

export default reports_ui_details
