import states from "./states"
import { DOMAIN_UPDATE_STATE } from "./actiontypes"

const domain_details = (state = states.domain_details, action) => {
  switch (action.type) {
    case DOMAIN_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default domain_details
