import React, { memo } from "react"
import PropTypes from "prop-types"
import { theme as themeConfig } from "antd"

const TableItemProgress = memo(({ value }) => {
  const { token } = themeConfig.useToken()
  if (typeof value === "number") {
    const opacity = value * 0.01
    return (
      <div className="ori-flex-column ori-flex-jc ori-flex-ac">
        <p className="">{value}%</p>
        <div
          className="ori-width-70"
          style={{
            opacity: opacity <= 0.15 ? 0.15 : opacity,
            paddingTop: token.paddingXXS,
            border: `1px solid ${token.colorBorderSecondary}`,
            borderRadius: token.borderRadius,
            backgroundColor: token.colorPrimary
          }}
        />
      </div>
    )
  } else return null
})

TableItemProgress.propTypes = {
  value: PropTypes.number,
}

export default TableItemProgress
