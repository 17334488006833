import { ML_UPDATE_STATE, ML_UPDATE_CONFIG_DATA } from "./actiontypes"
import states from "./states"

const ml_configuration_details = (
  state = states.ml_configuration_details,
  action
) => {
  switch (action.type) {
    case ML_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case ML_UPDATE_CONFIG_DATA: {
      return {
        ...state,
        ml_list_config: {
          ...state.ml_list_config,
          [action.payload.parentKey]: {
            ...state.ml_list_config[action.payload.parentKey],
            [action.payload.childKey]: action.payload.value,
          },
        },
      }
    }

    default:
      return state
  }
}

export default ml_configuration_details
