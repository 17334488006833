const states = {
  ml_configuration_details: {
    ml_list_config: {},
    ml_list_dependent: {},
    ml_initial_data: {
      config: {},
      dependent: {},
    },
  },
}

export default states
