import { getDateRange } from "../../utils/common"

const dateRange = getDateRange()

const states = {
  feedback_details: {
    feedbacks: [],
    filters: {
      startDate: dateRange[0],
      endDate: dateRange[1],
      platform: "all"
    },
    selected_feedback: null,
    messages: [],
    loading: false,
    load_error: false,
    fb_current_page: 1,
    fb_page_size: 10,
    fb_total: 0,
  },
}

export default states
