import * as actionTypes from "./actiontypes"
import states from "./states"

const chat_constants = (state = states, action) => {
  switch (action.type) {
    case actionTypes.GET_CHAT_CONSTANTS:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.SAVE_CHAT_CONSTANTS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default chat_constants
