const states = {
  action_details: {
    page_size: 10,
    current_page: 1,
    sort_order: null,
    sort_field: null,
    search_key: "",
    total_records: 0,
    loading: false,
    load_error: false,
    records: [],
    suggestions: [],
  },
}

export default states
