const states = {
  brand_functions_details: {
    brand_functions: [],
    total_bf_count: 0,
    bf_table_current_page: 1,
    bf_loading: false,
    selected_bf: {},
    file_imported: ""
  }
}

export default states
