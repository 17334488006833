export const UPDATE_PAGE_STATE = "UPDATE_PAGE_STATE"
export const GLOBAL_UPDATE_MESSAGE = "GLOBAL_UPDATE_MESSAGE"
export const GLOBAL_UPDATE_CHATLIST_ITEM = "GLOBAL_UPDATE_CHATLIST_ITEM"
export const GLOBAL_UPDATE_SELECTED_CHAT = "GLOBAL_UPDATE_SELECTED_CHAT"
export const GLOBAL_UPDATE_ACTIVE_CHATS_ITEM = "GLOBAL_UPDATE_ACTIVE_CHATS_ITEM"

// export const PAGE_CHANGED = "PAGE_CHANGED"
// export const PAGE_LOADING = "PAGE_LOADING"
// export const PAGE_LOADED = "PAGE_LOADED"

// export const SHOW_WEB_NOTIFICATON = "SHOW_WEB_NOTIFICATON"
// export const RESET_WEB_NOTIFICATON = "RESET_WEB_NOTIFICATON"
