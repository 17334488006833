const states = {
  users_journeys_details: {
    journeyList: [],
    jl_error: false,
    jl_loading: false,

    //--------------------- JOURNEY TREE   ----------------------

    tree_data: {},
    updatedAlias:{}
  },
}

export default states
