import states from "./states"
import {
  UPDATE_VARIABLE_STATE,
  VARIABLE_CREATED,
  VARIABLE_DELETED,
  VARIABLE_UPDATED,
} from "./actiontypes"

const botparams_details = (state = states.botparams_details, action) => {
  switch (action.type) {
    case UPDATE_VARIABLE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case VARIABLE_DELETED: {
      const index = state.variables.findIndex(
        variable => variable._id === action.payload
      )
      return {
        ...state,
        variables:
          index !== -1
            ? [
                ...state.variables.slice(0, index),
                ...state.variables.slice(index + 1),
              ]
            : [...state.variables],
      }
    }

    case VARIABLE_CREATED: {
      return {
        ...state,
        variables: [action.payload, ...state.variables],
      }
    }

    case VARIABLE_UPDATED: {
      const index = state.variables.findIndex(
        item => item._id === action.payload._id
      )
      if (index >= 0) {
        return {
          ...state,
          variables: [
            ...state.variables.slice(0, index),
            { ...action.payload },
            ...state.variables.slice(index + 1),
          ],
        }
      }
      return state
    }

    default:
      return state
  }
}

export default botparams_details
