import states from "./states"
import {
  UPDATE_SESSION_FLOW_STATE,
  UPDATE_FILTER_DATA,
  UPDATE_DEFAULT_FILTERS,
} from "./actiontypes"
import dayjs from "dayjs"

const session_flow_details = (state = states.session_flow_details, action) => {
  switch (action.type) {
    case UPDATE_SESSION_FLOW_STATE: {
      let session_flow_filters = { ...state.session_flow_filters }
      if (action.payload.filter) {
        for (let index in action.payload.filter) {
          let indFilter = action.payload.filter[index]
          session_flow_filters[indFilter.filterKey] = indFilter.default || ""
        }
      }
      return {
        ...state,
        ...action.payload,
        session_flow_filters,
      }
    }
    case UPDATE_FILTER_DATA: {
      let session_flow_filters = { ...state.session_flow_filters }
      session_flow_filters[action.payload.key] = action.payload.value

      return {
        ...state,
        session_flow_filters,
      }
    }
    case UPDATE_DEFAULT_FILTERS: {
      let filtersPayload = {
        startDate: dayjs().subtract(1, "day"),
        endDate: dayjs(),
      }
      for (let index in action.payload) {
        let indFilter = action.payload[index]
        filtersPayload[indFilter.filterKey] = indFilter.default || ""
      }

      return {
        ...state,
        session_flow_default_filters: filtersPayload,
      }
    }
    default:
      return state
  }
}

export default session_flow_details
