const states = {
  testcases_details: {
    testcases_list: [],
    messages: [],
    testcases_filters : {},
    selected_filters: {
      // isActive: true,
      tags: [],
    },
    loading: false,
    load_error: false,
  },
}

export default states
